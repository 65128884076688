interface ArrowDoubleDownIconProps {
  className?: string;
}

export const ArrowDoubleDownIcon = ({ className }: ArrowDoubleDownIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8L8 18M8 18L5 15M8 18L11 15"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 8L16 18M16 18L13 15M16 18L19 15"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="4" y1="6" x2="20" y2="6" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
