import { DEFAULT_ERROR } from '@/constants/errors';
import {
  ModelElementsState,
  SearchModelElementDataState,
} from '@/redux/modelElements/modelElements.types';
import { FetchDataState, MultiFetchDataState, MultiSearchData } from '@/types/fetchDataState';
import { ModelElement } from '@/types/models';
import { HISTAMINE_MAP_ID } from '@/constants/mocks';
import { modelElementFixture } from '@/models/fixtures/modelElementFixture';

export const MODEL_ELEMENTS_SEARCH_INITIAL_STATE_MOCK: MultiFetchDataState<
  SearchModelElementDataState[]
> = {
  data: [],
  loading: 'idle',
  error: DEFAULT_ERROR,
};

export const MODEL_ELEMENTS_INITIAL_STATE_MOCK: ModelElementsState = {
  data: {},
  search: MODEL_ELEMENTS_SEARCH_INITIAL_STATE_MOCK,
};

export const MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK: FetchDataState<Array<ModelElement>> = {
  data: [],
  loading: 'idle',
  error: DEFAULT_ERROR,
};

export const MODEL_ELEMENT_LINKING_TO_SUBMAP: ModelElement = {
  ...modelElementFixture,
  submodel: {
    mapId: HISTAMINE_MAP_ID,
    type: 'DONWSTREAM_TARGETS',
  },
};

export const MODEL_ELEMENTS_SEARCH_LINKING_TO_SUBMAP_DATA_MOCK: MultiSearchData<
  SearchModelElementDataState[]
>[] = [
  {
    data: [{ modelElement: MODEL_ELEMENT_LINKING_TO_SUBMAP, perfect: false }],
    searchQueryElement: '',
    loading: 'succeeded',
    error: DEFAULT_ERROR,
  },
];
