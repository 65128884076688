import React from 'react';

interface TextIconProps {
  className?: string;
}

export const TextIcon = ({ className }: TextIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="26"
      fill="currentColor"
      fontFamily="Times New Roman, serif"
      fontWeight="bold"
    >
      T
    </text>
  </svg>
);
