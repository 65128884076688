interface DotsIconProps {
  className?: string;
}

export const DotsIcon = ({ className }: DotsIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.83317 7.00065C5.83317 7.64498 6.35551 8.16732 6.99984 8.16732C7.64417 8.16732 8.1665 7.64498 8.1665 7.00065C8.1665 6.35632 7.64417 5.83398 6.99984 5.83398C6.35551 5.83398 5.83317 6.35632 5.83317 7.00065Z" />
    <path d="M5.83317 2.91667C5.83317 3.561 6.35551 4.08333 6.99984 4.08333C7.64417 4.08333 8.1665 3.561 8.1665 2.91667C8.1665 2.27233 7.64417 1.75 6.99984 1.75C6.35551 1.75 5.83317 2.27233 5.83317 2.91667Z" />
    <path d="M5.83317 11.0827C5.83317 11.727 6.35551 12.2493 6.99984 12.2493C7.64417 12.2493 8.1665 11.727 8.1665 11.0827C8.1665 10.4384 7.64417 9.91602 6.99984 9.91602C6.35551 9.91602 5.83317 10.4383 5.83317 11.0827Z" />
  </svg>
);
