import { z } from 'zod';
import { ZERO } from '@/constants/common';

export const mapOverlaySchema = z.object({
  id: z.number().int().positive(),
  group: z.number().int().positive().nullable(),
  name: z.string(),
  order: z.number().int().gte(ZERO),
  creator: z.string(),
  description: z.string(),
  genomeType: z.string().nullable(),
  genomeVersion: z.string().nullable(),
  publicOverlay: z.boolean(),
  type: z.string(),
});

export const createdOverlayFileSchema = z.object({
  id: z.number(),
  filename: z.string(),
  length: z.number(),
  owner: z.string(),
  uploadedDataLength: z.number(),
});

export const uploadedOverlayFileContentSchema = createdOverlayFileSchema.extend({});
