import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  getModelElementsForModel,
  searchModelElement,
  searchMultiModelElements,
} from '@/redux/modelElements/modelElements.thunks';
import {
  ModelElementsState,
  SearchModelElementDataState,
} from '@/redux/modelElements/modelElements.types';
import { MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK } from '@/redux/modelElements/modelElements.mock';
import { DEFAULT_ERROR } from '@/constants/errors';
import { ModelElement } from '@/types/models';

export const getModelElementsReducer = (
  builder: ActionReducerMapBuilder<ModelElementsState>,
): void => {
  builder.addCase(getModelElementsForModel.pending, (state, action) => {
    const modelId = action.meta.arg;
    if (state.data[modelId]) {
      state.data[modelId].loading = 'pending';
    } else {
      state.data[modelId] = { ...MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK, loading: 'pending' };
    }
  });
  builder.addCase(getModelElementsForModel.fulfilled, (state, action) => {
    const modelId = action.meta.arg;
    const data = action.payload || [];
    if (state.data[modelId]) {
      state.data[modelId].data = data;
      state.data[modelId].loading = 'succeeded';
    } else {
      state.data[modelId] = { data, loading: 'pending', error: DEFAULT_ERROR };
    }
  });
  builder.addCase(getModelElementsForModel.rejected, (state, action) => {
    const modelId = action.meta.arg;
    if (state.data[modelId]) {
      state.data[modelId].loading = 'failed';
    } else {
      state.data[modelId] = { ...MODEL_ELEMENTS_STATE_INITIAL_MODEL_MOCK, loading: 'failed' };
    }
  });
};

export const searchModelElementReducer = (
  builder: ActionReducerMapBuilder<ModelElementsState>,
): void => {
  builder.addCase(searchModelElement.pending, (state, action) => {
    state.search.data.push({
      searchQueryElement: action.meta.arg.searchQuery,
      data: undefined,
      loading: 'pending',
      error: DEFAULT_ERROR,
    });
  });
  builder.addCase(searchModelElement.fulfilled, (state, action) => {
    const bioEntities = state.search.data.find(
      bioEntity => bioEntity.searchQueryElement === action.meta.arg.searchQuery,
    );
    if (bioEntities) {
      bioEntities.data = action.payload?.map(data => ({
        modelElement: data.bioEntity,
        perfect: data.perfect,
      }));
      bioEntities.loading = 'succeeded';
    }
  });
  builder.addCase(searchModelElement.rejected, (state, action) => {
    const bioEntities = state.search.data.find(
      bioEntity => bioEntity.searchQueryElement === action.meta.arg.searchQuery,
    );
    if (bioEntities) {
      bioEntities.loading = 'failed';
    }
  });
};

export const searchMultiModelElementsReducer = (
  builder: ActionReducerMapBuilder<ModelElementsState>,
): void => {
  builder.addCase(searchMultiModelElements.pending, state => {
    state.search.data = [];
    state.search.loading = 'pending';
  });
  builder.addCase(searchMultiModelElements.fulfilled, state => {
    state.search.loading = 'succeeded';
  });
  builder.addCase(searchMultiModelElements.rejected, state => {
    state.search.loading = 'failed';
  });
};

export const setModelElementSearchReducer = (
  state: ModelElementsState,
  action: PayloadAction<SearchModelElementDataState>,
): void => {
  state.search = {
    data: [
      {
        data: [action.payload],
        loading: 'succeeded',
        error: DEFAULT_ERROR,
        searchQueryElement: action.payload.modelElement.id.toString(),
      },
    ],
    loading: 'succeeded',
    error: DEFAULT_ERROR,
  };
};

export const setMultipleModelElementSearchReducer = (
  state: ModelElementsState,
  action: PayloadAction<ModelElement[]>,
): void => {
  state.search.data = action.payload.map(modelElement => {
    return {
      data: [
        {
          modelElement,
          perfect: true,
        },
      ],
      loading: 'succeeded',
      error: DEFAULT_ERROR,
      searchQueryElement: modelElement.id.toString(),
    };
  });
  state.search.loading = 'succeeded';
};

export const clearSearchModelElementsReducer = (state: ModelElementsState): void => {
  state.search.data = [];
  state.search.loading = 'idle';
};
