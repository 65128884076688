import { DEFAULT_ZOOM } from '@/constants/map';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getPointMerged } from '@/utils/object/getPointMerged';
import {
  initMapBackground,
  initMapPosition,
  initMapSizeAndModelId,
  initOpenedMaps,
} from './map.thunks';
import {
  CloseMapAction,
  CloseMapActionAndSetMainMapActive,
  MapState,
  OpenMapAndSetActiveAction,
  SetActiveMapAction,
  SetBackgroundAction,
  SetLastClickPositionAction,
  SetLastPositionZoomAction,
  SetLastPositionZoomWithDeltaAction,
  SetMapDataAction,
  SetMapPositionDataAction,
} from './map.types';

export const setMapDataReducer = (state: MapState, action: SetMapDataAction): void => {
  const payload = action.payload || {};

  state.data = {
    ...state.data,
    ...payload,
  };
};

export const setMapPositionReducer = (state: MapState, action: SetMapPositionDataAction): void => {
  const position = action.payload || {};
  const statePosition = state.data.position;
  const lastPosition = statePosition.last;
  const lastZoom = lastPosition.z;
  const finalPosition = getPointMerged(position || {}, lastPosition);
  const { modelId } = state.data;

  if (lastPosition?.x !== finalPosition.x || lastPosition?.y !== finalPosition.y) {
    PluginsEventBus.dispatchEvent('onCenterChanged', {
      modelId,
      x: finalPosition.x,
      y: finalPosition.y,
    });
  }

  if (position?.z && lastZoom && lastZoom !== position?.z) {
    PluginsEventBus.dispatchEvent('onZoomChanged', {
      modelId,
      zoom: position?.z,
    });
  }

  state.data = {
    ...state.data,
    position: {
      initial: finalPosition,
      last: finalPosition,
    },
  };
};

export const varyPositionZoomReducer = (
  state: MapState,
  action: SetLastPositionZoomWithDeltaAction,
): void => {
  const { minZoom, maxZoom } = state.data.size;
  const { delta } = action.payload;
  const currentZ = state.data.position.last.z || DEFAULT_ZOOM;
  const newZ = currentZ + delta;
  const newZLimited = Math.min(Math.max(newZ, minZoom), maxZoom);

  if (state.data.position.last.z !== newZLimited) {
    PluginsEventBus.dispatchEvent('onZoomChanged', {
      modelId: state.data.modelId,
      zoom: newZLimited,
    });
  }

  state.data.position.last.z = newZLimited;
  state.data.position.initial.z = newZLimited;
};

export const setLastPositionZoomReducer = (
  state: MapState,
  action: SetLastPositionZoomAction,
): void => {
  const { zoom } = action.payload;

  if (state.data.position.last.z !== zoom) {
    PluginsEventBus.dispatchEvent('onZoomChanged', {
      modelId: state.data.modelId,
      zoom,
    });
  }

  state.data.position.last.z = zoom;
  state.data.position.initial.z = zoom;
};

export const updateLastClickReducer = (
  state: MapState,
  action: SetLastClickPositionAction,
): void => {
  state.data.lastClick.modelId = action.payload.modelId;
  state.data.lastClick.position = action.payload.coordinates;
};

export const updateLastRightClickReducer = (
  state: MapState,
  action: SetLastClickPositionAction,
): void => {
  state.data.lastRightClick.modelId = action.payload.modelId;
  state.data.lastRightClick.position = action.payload.coordinates;
};

const updateLastPositionOfCurrentlyActiveMap = (state: MapState): void => {
  const currentMapId = state.data.modelId;
  const currentOpenedMap = state.openedMaps.find(openedMap => openedMap.modelId === currentMapId);
  if (currentOpenedMap) {
    currentOpenedMap.lastPosition = state.data.position.last;
  }
};

export const setActiveMapReducer = (state: MapState, action: SetActiveMapAction): void => {
  updateLastPositionOfCurrentlyActiveMap(state);
  state.data.modelId = action.payload.modelId;
};

export const openMapAndSetActiveReducer = (
  state: MapState,
  action: OpenMapAndSetActiveAction,
): void => {
  updateLastPositionOfCurrentlyActiveMap(state);

  state.openedMaps.push({
    modelId: action.payload.modelId,
    modelName: action.payload.modelName,
    lastPosition: { x: 0, y: 0, z: 0 },
  });
  state.data.modelId = action.payload.modelId;
};

export const openMapAndOrSetActiveIfSelectedReducer = (
  state: MapState,
  action: OpenMapAndSetActiveAction,
): void => {
  const { modelId, modelName } = action.payload;
  const { openedMaps, data } = state;
  const isMapAlreadyOpened = openedMaps.some(map => map.modelId === modelId);
  const currentMapModelId = data.modelId;
  updateLastPositionOfCurrentlyActiveMap(state);

  if (currentMapModelId !== modelId) {
    PluginsEventBus.dispatchEvent('onSubmapClose', currentMapModelId);
    PluginsEventBus.dispatchEvent('onSubmapOpen', modelId);
  }

  if (isMapAlreadyOpened) {
    state.data.modelId = modelId;
    return;
  }

  state.openedMaps.push({
    modelId,
    modelName,
    lastPosition: { x: 0, y: 0, z: 0 },
  });
  state.data.modelId = modelId;
};

export const closeMapReducer = (state: MapState, action: CloseMapAction): void => {
  state.openedMaps = state.openedMaps.filter(
    openedMap => openedMap.modelId !== action.payload.modelId,
  );
};

export const closeMapAndSetMainMapActiveReducer = (
  state: MapState,
  action: CloseMapActionAndSetMainMapActive,
): void => {
  state.openedMaps = state.openedMaps.filter(
    openedMap => openedMap.modelId !== action.payload.currentModelId,
  );
  state.data.modelId = action.payload.modelId;
};

export const setMapBackgroundReducer = (state: MapState, action: SetBackgroundAction): void => {
  if (action.payload !== state.data.backgroundId) {
    PluginsEventBus.dispatchEvent('onBackgroundOverlayChange', action.payload);
  }

  state.data.backgroundId = action.payload;
};

export const initMapSizeAndModelIdReducer = (builder: ActionReducerMapBuilder<MapState>): void => {
  builder.addCase(initMapSizeAndModelId.fulfilled, (state, action) => {
    state.data.modelId = action.payload.modelId;
    state.data.size = action.payload.size;
  });
};

export const initMapPositionReducers = (builder: ActionReducerMapBuilder<MapState>): void => {
  builder.addCase(initMapPosition.fulfilled, (state, action) => {
    state.data.position = action.payload;
  });
};

export const initMapBackgroundsReducer = (builder: ActionReducerMapBuilder<MapState>): void => {
  builder.addCase(initMapBackground.fulfilled, (state, action) => {
    state.data.backgroundId = action.payload;
    state.loading = 'succeeded';
  });
};

export const initOpenedMapsReducer = (builder: ActionReducerMapBuilder<MapState>): void => {
  builder.addCase(initOpenedMaps.fulfilled, (state, action) => {
    state.openedMaps = action.payload;
  });
};
