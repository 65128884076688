import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { OverlayGroupsState } from '@/redux/overlayGroup/overlayGroup.types';
import { getOverlayGroups } from '@/redux/overlayGroup/overlayGroup.thunks';

export const getOverlayGroupsReducer = (
  builder: ActionReducerMapBuilder<OverlayGroupsState>,
): void => {
  builder.addCase(getOverlayGroups.pending, state => {
    state.loading = 'pending';
  });
  builder.addCase(getOverlayGroups.fulfilled, (state, action) => {
    state.data = action.payload || undefined;
    state.loading = 'succeeded';
  });
  builder.addCase(getOverlayGroups.rejected, state => {
    state.loading = 'failed';
    // TODO to discuss manage state of failure
  });
};
