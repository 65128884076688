import { createSlice } from '@reduxjs/toolkit';
import { LAYERS_STATE_INITIAL_MOCK } from '@/redux/layers/layers.mock';
import {
  getLayerImageReducer,
  getLayerTextReducer,
  getLayersForModelReducer,
  layerAddImageReducer,
  layerAddTextReducer,
  layerDeleteImageReducer,
  layerUpdateImageReducer,
  setLayerVisibilityReducer,
  setDrawLayerReducer,
  setLayerToInactiveReducer,
  setLayerToActiveReducer,
  layerDeleteTextReducer,
  layerUpdateTextReducer,
  layerAddRectReducer,
  layerUpdateRectReducer,
  layerDeleteRectReducer,
  layerAddOvalReducer,
  getLayerReducer,
  layerDeleteReducer,
  layerUpdateOvalReducer,
  layerDeleteOvalReducer,
  layerAddLineReducer,
  layerUpdateLineReducer,
  layerDeleteLineReducer,
} from '@/redux/layers/layers.reducers';

export const layersSlice = createSlice({
  name: 'layers',
  initialState: LAYERS_STATE_INITIAL_MOCK,
  reducers: {
    layerDelete: layerDeleteReducer,
    setLayerVisibility: setLayerVisibilityReducer,
    setLayerToInactive: setLayerToInactiveReducer,
    setLayerToActive: setLayerToActiveReducer,
    setDrawLayer: setDrawLayerReducer,
    layerAddImage: layerAddImageReducer,
    layerUpdateImage: layerUpdateImageReducer,
    layerDeleteImage: layerDeleteImageReducer,
    layerAddText: layerAddTextReducer,
    layerUpdateText: layerUpdateTextReducer,
    layerDeleteText: layerDeleteTextReducer,
    layerAddRect: layerAddRectReducer,
    layerUpdateRect: layerUpdateRectReducer,
    layerDeleteRect: layerDeleteRectReducer,
    layerAddOval: layerAddOvalReducer,
    layerUpdateOval: layerUpdateOvalReducer,
    layerDeleteOval: layerDeleteOvalReducer,
    layerAddLine: layerAddLineReducer,
    layerUpdateLine: layerUpdateLineReducer,
    layerDeleteLine: layerDeleteLineReducer,
  },
  extraReducers: builder => {
    getLayersForModelReducer(builder);
    getLayerImageReducer(builder);
    getLayerTextReducer(builder);
    getLayerReducer(builder);
  },
});

export const {
  layerDelete,
  setLayerVisibility,
  setLayerToInactive,
  setLayerToActive,
  setDrawLayer,
  layerAddImage,
  layerUpdateImage,
  layerDeleteImage,
  layerAddText,
  layerUpdateText,
  layerDeleteText,
  layerAddRect,
  layerUpdateRect,
  layerDeleteRect,
  layerAddOval,
  layerUpdateOval,
  layerDeleteOval,
  layerAddLine,
  layerUpdateLine,
  layerDeleteLine,
} = layersSlice.actions;

export default layersSlice.reducer;
