import { OverviewImageView } from '@/types/models';
import { createSelector } from '@reduxjs/toolkit';
import { OVERVIEW_IMAGE_ID_DEFAULT } from '../map/map.constants';
import { currentOverviewImageIdSelector } from '../modal/modal.selector';
import { rootSelector } from '../root/root.selectors';

export const projectSelector = createSelector(rootSelector, state => state.project);

export const projectDataSelector = createSelector(projectSelector, project => project?.data);

export const projectDefaultOverviewImageIdSelector = createSelector(
  projectDataSelector,
  projectData => projectData?.topOverviewImage?.id || OVERVIEW_IMAGE_ID_DEFAULT,
);

export const currentOverviewImageSelector = createSelector(
  projectDataSelector,
  currentOverviewImageIdSelector,
  (projectData, imageId): OverviewImageView | undefined =>
    (projectData?.overviewImageViews || []).find(overviewImage => overviewImage.id === imageId),
);

export const projectOverviewImagesSelector = createSelector(
  projectDataSelector,
  (projectData): OverviewImageView[] => projectData?.overviewImageViews || [],
);

export const projectDirectorySelector = createSelector(
  projectDataSelector,
  projectData => projectData?.directory,
);

export const projectIdSelector = createSelector(
  projectDataSelector,
  projectData => projectData?.projectId,
);

export const projectSbgnFormatSelector = createSelector(
  projectDataSelector,
  projectData => projectData?.sbgnFormat || false,
);

export const projectNameSelector = createSelector(
  projectDataSelector,
  projectData => projectData?.name,
);

export const diseaseNameSelector = createSelector(
  projectDataSelector,
  projectData => projectData?.diseaseName,
);

export const diseaseLinkSelector = createSelector(projectDataSelector, projectData =>
  projectData?.disease?.link ? projectData?.disease?.link : undefined,
);

export const organismLinkSelector = createSelector(projectDataSelector, projectData =>
  projectData?.organism?.link ? projectData?.organism?.link : undefined,
);

export const organismNameSelector = createSelector(
  projectDataSelector,
  projectData => projectData?.organismName,
);

export const versionSelector = createSelector(projectDataSelector, state => state?.version);

export const projectMainIdSelector = createSelector(projectSelector, project => project.projectId);
