import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';

export const layerTextSchema = z.object({
  id: z.number(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  layer: z.number(),
  fontSize: z.number(),
  notes: z.string(),
  verticalAlign: z.enum(['TOP', 'MIDDLE', 'BOTTOM']),
  horizontalAlign: z.enum(['LEFT', 'RIGHT', 'CENTER']),
  backgroundColor: colorSchema,
  borderColor: colorSchema,
  color: colorSchema,
});
