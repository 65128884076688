import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAppSelector } from '@/redux/hooks/useAppSelector';
import { userTokenSelector } from '@/redux/user/user.selectors';
import { projectIdSelector } from '@/redux/project/project.selectors';
import { BASE_API_URL } from '@/constants';
import { ACKNOWLEDGE_OPERATION_TYPES } from '@/utils/websocket-entity-updates/webSocketEntityUpdates.constants';
import {
  WebSocketAcknowledgeInterface,
  WebSocketEntityUpdateInterface,
} from '@/utils/websocket-entity-updates/webSocketEntityUpdates.types';

export interface WebSocketEntityUpdatesContextInterface {
  sendMessage: (msg: string) => void;
  lastJsonMessage: WebSocketEntityUpdateInterface | WebSocketAcknowledgeInterface | null;
  readyState: ReadyState;
}

const WebSocketEntityUpdatesContext = createContext<
  WebSocketEntityUpdatesContextInterface | undefined
>(undefined);
const SOCKET_URL = `${BASE_API_URL.replace('https', 'wss')}/websocket/entity-updates`;
const LOGIN_COMMAND_ID = 'login';

export const WebSocketEntityUpdatesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const userToken = useAppSelector(userTokenSelector);
  const projectId = useAppSelector(projectIdSelector);
  const [isLogged, setIsLogged] = useState(false);
  const { lastJsonMessage, sendMessage, readyState } = useWebSocket<
    WebSocketEntityUpdateInterface | WebSocketAcknowledgeInterface
  >(SOCKET_URL, {
    shouldReconnect: () => true,
    reconnectInterval: 5000,
    share: true,
    onMessage: (messageEvent: MessageEvent) => {
      try {
        const data = JSON.parse(messageEvent.data);
        if (
          data.commandId === LOGIN_COMMAND_ID &&
          data.type === ACKNOWLEDGE_OPERATION_TYPES.MESSAGE_PROCESSED_SUCCESSFULLY &&
          data.message === 'ok'
        ) {
          setIsLogged(true);
        }
      } catch {
        throw new Error('Websocket message parsing error');
      }
    },
  });

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }
    if (userToken && !isLogged) {
      sendMessage(
        JSON.stringify({ command: 'login', token: userToken, commandId: LOGIN_COMMAND_ID }),
      );
    } else if (!userToken) {
      setIsLogged(false);
    }
  }, [isLogged, readyState, sendMessage, userToken]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    if (projectId && isLogged) {
      sendMessage(JSON.stringify({ command: 'register-for-project-updates', projectId }));
    }
  }, [projectId, readyState, sendMessage, isLogged]);

  const contextValue = useMemo(
    () => ({ sendMessage, lastJsonMessage, readyState }),
    [sendMessage, lastJsonMessage, readyState],
  );

  return (
    <WebSocketEntityUpdatesContext.Provider value={contextValue}>
      {children}
    </WebSocketEntityUpdatesContext.Provider>
  );
};

export const useWebSocketEntityUpdatesContext = (): WebSocketEntityUpdatesContextInterface => {
  const context = useContext(WebSocketEntityUpdatesContext);
  if (!context) {
    throw new Error(
      'useWebSocketEntityUpdatesContext must be used inside a WebSocketEntityUpdatesProvider',
    );
  }
  return context;
};
