import { JSX } from 'react';

interface PadlockLockedIconProps {
  className?: string;
}

export const PadlockLockedIcon = ({ className }: PadlockLockedIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="1.5"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="10" width="14" height="10" rx="2" fill="none" />
    <path d="M8 10V6a4 4 0 0 1 8 0v4" fill="none" />
  </svg>
);
