interface LineIconProps {
  className?: string;
}

export const LineIcon = ({ className }: LineIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 200 200"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <defs>
      <marker
        id="arrow"
        markerWidth="10"
        markerHeight="10"
        refX="5"
        refY="5"
        orient="auto"
        markerUnits="strokeWidth"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
      </marker>
    </defs>
    <polyline
      points="20,180 80,100 140,140 180,40"
      stroke="black"
      strokeWidth="8"
      fill="none"
      strokeLinejoin="round"
      markerEnd="url(#arrow)"
    />
  </svg>
);
