import { z } from 'zod';
import { reactionProduct } from '@/models/reactionProduct';
import { lineSchema } from './lineSchema';
import { operatorSchema } from './operatorSchema';
import { referenceSchema } from './referenceSchema';

export const publicationElementSchema = z.object({
  abbreviation: z.string().nullable(),
  elementId: z.string(),
  formula: z.string().nullable(),
  geneProteinReaction: z.string().nullable().optional(),
  id: z.union([z.number().int().positive(), z.string()]),
  idReaction: z.string().optional(),
  kinetics: z.null().optional(),
  line: lineSchema.optional(),
  lowerBound: z.boolean().nullable().optional(),
  mechanicalConfidenceScore: z.boolean().nullable().optional(),
  model: z.number(),
  modifiers: z.array(reactionProduct).optional(),
  name: z.string(),
  notes: z.string(),
  operators: z.array(operatorSchema).optional(),
  processCoordinates: z.null().optional(),
  products: z.array(reactionProduct).optional(),
  reactants: z.array(reactionProduct).optional(),
  references: z.array(referenceSchema),
  reversible: z.boolean().optional(),
  sboTerm: z.string(),
  subsystem: z.string().nullable().optional(),
  symbol: z.string().nullable(),
  synonyms: z.array(z.string()),
  upperBound: z.boolean().nullable().optional(),
  visibilityLevel: z.string(),
  z: z.number(),
});
