import { LayerOval } from '@/types/models';
import { LayersDictState } from '@/redux/layers/layers.types';

export default function setLayerOval({
  layerId,
  layers,
  layerOval,
}: {
  layerId: number;
  layers: LayersDictState;
  layerOval: LayerOval;
}): void {
  const layer = layers[layerId];
  if (!layer) {
    return;
  }
  layer.ovals[layerOval.id] = layerOval;
}
