import { createSlice } from '@reduxjs/toolkit';
import { DRAWER_INITIAL_STATE } from './drawer.constants';
import {
  closeDrawerReducer,
  displayAddOverlayGroupDrawerReducer,
  displayAddOverlaysDrawerReducer,
  displayBioEntitiesListReducer,
  displayChemicalsListReducer,
  displayDrugsListReducer,
  displayEntityDetailsReducer,
  displayGroupedSearchResultsReducer,
  getBioEntityChemicalsForTargetReducers,
  getBioEntityDrugsForTargetReducers,
  openBioEntityDrawerByIdReducer,
  openCommentDrawerByIdReducer,
  openDrawerReducer,
  openOverlaysDrawerReducer,
  openReactionDrawerByIdReducer,
  openSearchDrawerWithSelectedTabReducer,
  openSubmapsDrawerReducer,
  selectTabReducer,
} from './drawer.reducers';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: DRAWER_INITIAL_STATE,
  reducers: {
    openDrawer: openDrawerReducer,
    openSearchDrawerWithSelectedTab: openSearchDrawerWithSelectedTabReducer,
    openSubmapsDrawer: openSubmapsDrawerReducer,
    openOverlaysDrawer: openOverlaysDrawerReducer,
    displayAddOverlaysDrawer: displayAddOverlaysDrawerReducer,
    displayAddOverlayGroupDrawer: displayAddOverlayGroupDrawerReducer,
    selectTab: selectTabReducer,
    closeDrawer: closeDrawerReducer,
    displayDrugsList: displayDrugsListReducer,
    displayChemicalsList: displayChemicalsListReducer,
    displayBioEntitiesList: displayBioEntitiesListReducer,
    displayGroupedSearchResults: displayGroupedSearchResultsReducer,
    displayEntityDetails: displayEntityDetailsReducer,
    openReactionDrawerById: openReactionDrawerByIdReducer,
    openBioEntityDrawerById: openBioEntityDrawerByIdReducer,
    openCommentDrawerById: openCommentDrawerByIdReducer,
  },
  extraReducers: builder => {
    getBioEntityDrugsForTargetReducers(builder);
    getBioEntityChemicalsForTargetReducers(builder);
  },
});

export const {
  openDrawer,
  openSearchDrawerWithSelectedTab,
  openSubmapsDrawer,
  openOverlaysDrawer,
  displayAddOverlaysDrawer,
  displayAddOverlayGroupDrawer,
  selectTab,
  closeDrawer,
  displayDrugsList,
  displayChemicalsList,
  displayBioEntitiesList,
  displayGroupedSearchResults,
  displayEntityDetails,
  openReactionDrawerById,
  openBioEntityDrawerById,
  openCommentDrawerById,
} = drawerSlice.actions;

export default drawerSlice.reducer;
