import { apiPath } from '@/redux/apiPath';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { BioEntityContent, BioEntityResponse, ModelElement, ModelElements } from '@/types/models';
import {
  MODEL_ELEMENT_SEARCH_ERROR_PREFIX,
  MODEL_ELEMENTS_FETCHING_ERROR_PREFIX,
  MULTI_MODEL_ELEMENTS_SEARCH_ERROR_PREFIX,
} from '@/redux/modelElements/modelElements.constants';
import { modelElementSchema } from '@/models/modelElementSchema';
import { pageableSchema } from '@/models/pageableSchema';
import { addNumbersToEntityNumberData } from '@/redux/entityNumber/entityNumber.slice';
import {
  SearchModelElementProps,
  SearchMultiModelElementsActions,
  SearchMultiModelElementsProps,
} from '@/redux/modelElements/modelElements.types';
import { bioEntityResponseSchema } from '@/models/bioEntityResponseSchema';

export const getModelElementsForModel = createAsyncThunk<
  Array<ModelElement> | undefined,
  number,
  ThunkConfig
>('modelElements/getModelElementsForModel', async (modelId: number) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<ModelElements>(
      apiPath.getModelElements(modelId),
    );
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(modelElementSchema));
    return isDataValid ? data.content : undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: MODEL_ELEMENTS_FETCHING_ERROR_PREFIX }));
  }
});

export const searchModelElement = createAsyncThunk<
  BioEntityContent[] | undefined,
  SearchModelElementProps,
  ThunkConfig
>(
  'modelElements/searchModelElement',
  async ({ searchQuery, isPerfectMatch, addNumbersToEntityNumber = true }, { dispatch }) => {
    try {
      const { data } = await axiosInstanceNewAPI.get<BioEntityResponse>(
        apiPath.getBioEntityContentsStringWithQuery({ searchQuery, isPerfectMatch }),
      );

      const isDataValid = validateDataUsingZodSchema(data, bioEntityResponseSchema);
      const { content } = data;
      if (addNumbersToEntityNumber && content) {
        const modelElementsIds = content.map(b => b.bioEntity.elementId);
        dispatch(addNumbersToEntityNumberData(modelElementsIds));
      }

      return isDataValid ? content : undefined;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MODEL_ELEMENT_SEARCH_ERROR_PREFIX }));
    }
  },
);

export const searchMultiModelElements = createAsyncThunk<
  BioEntityContent[],
  SearchMultiModelElementsProps,
  ThunkConfig
>(
  'modelElements/searchMultiModelElements',
  async ({ searchQueries, isPerfectMatch }, { dispatch }) => {
    try {
      const asyncGetBioEntityFunctions = searchQueries.map(searchQuery =>
        dispatch(
          searchModelElement({ searchQuery, isPerfectMatch, addNumbersToEntityNumber: false }),
        ),
      );

      const multiModelElementsActions = (await Promise.all(
        asyncGetBioEntityFunctions,
      )) as SearchMultiModelElementsActions;

      const multiModelElements = multiModelElementsActions
        .map(multiModelElementsAction => multiModelElementsAction?.payload || [])
        .flat()
        .filter((payload): payload is BioEntityContent => typeof payload !== 'string')
        .filter(payload => 'bioEntity' in payload || {});

      const modelElementsIds = multiModelElements.map(b => b.bioEntity.elementId);
      dispatch(addNumbersToEntityNumberData(modelElementsIds));

      return multiModelElements;
    } catch (error) {
      return Promise.reject(getError({ error, prefix: MULTI_MODEL_ELEMENTS_SEARCH_ERROR_PREFIX }));
    }
  },
);

export const updateElementGlyph = createAsyncThunk<
  null,
  {
    modelId: number;
    elementId: number;
    glyphId: number | null;
  },
  ThunkConfig
>('modelElements/updateElementGlyph', async ({ modelId, elementId, glyphId }) => {
  try {
    await axiosInstanceNewAPI.post(apiPath.updateElementGlyph(modelId, elementId), { id: glyphId });
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});
