interface RectangleIconProps {
  className?: string;
}

export const RectangleIcon = ({ className }: RectangleIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 100 100"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="21" width="92" height="58" stroke="black" fill="none" strokeWidth="4.5" />
  </svg>
);
