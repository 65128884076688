import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { OverlayBioEntity } from '@/types/models';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import {
  getValidOverlayBioEntities,
  parseOverlayBioEntityToOlRenderingFormat,
} from './overlayBioEntity.utils';
import { apiPath } from '../apiPath';
import { modelsIdsSelector } from '../models/models.selectors';
import type { RootState } from '../store';
import { overlaySelector, userOverlaySelector } from '../overlays/overlays.selectors';
import {
  INIT_OVERLAYS_ERROR_PREFIX,
  OVERLAY_BIO_ENTITY_ALL_MODELS_FETCHING_ERROR_PREFIX,
  OVERLAY_BIO_ENTITY_FETCHING_ERROR_PREFIX,
} from './overlayBioEntity.constants';

type GetOverlayBioEntityThunkProps = {
  overlayId: number;
  modelId: number;
};

export const getOverlayBioEntity = createAsyncThunk<
  OverlayBioEntityRender[] | undefined,
  GetOverlayBioEntityThunkProps,
  ThunkConfig
>('overlayBioEntity/getOverlayBioEntity', async ({ overlayId, modelId }) => {
  try {
    const response = await axiosInstanceNewAPI.get<OverlayBioEntity[]>(
      apiPath.getOverlayBioEntity({ overlayId, modelId }),
      {
        withCredentials: true,
      },
    );

    const validOverlayBioEntities = getValidOverlayBioEntities(response.data);

    if (validOverlayBioEntities) {
      return parseOverlayBioEntityToOlRenderingFormat(validOverlayBioEntities, overlayId);
    }

    return undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: OVERLAY_BIO_ENTITY_FETCHING_ERROR_PREFIX }));
  }
});

type GetOverlayBioEntityForAllModelsThunkProps = { overlayId: number };

export const getOverlayBioEntityForAllModels = createAsyncThunk<
  void,
  GetOverlayBioEntityForAllModelsThunkProps,
  { state: RootState } & ThunkConfig
>(
  'overlayBioEntity/getOverlayBioEntityForAllModels',
  // eslint-disable-next-line consistent-return
  async ({ overlayId }, { dispatch, getState }) => {
    try {
      const state = getState();
      const modelsIds = modelsIdsSelector(state);

      const asyncGetOverlayBioEntityFunctions = modelsIds.map(id =>
        dispatch(getOverlayBioEntity({ overlayId, modelId: id })),
      );

      await Promise.all(asyncGetOverlayBioEntityFunctions);
    } catch (error) {
      return Promise.reject(
        getError({ error, prefix: OVERLAY_BIO_ENTITY_ALL_MODELS_FETCHING_ERROR_PREFIX }),
      );
    }
  },
);

type GetInitOverlaysProps = { overlaysId: number[] };

export const getInitOverlays = createAsyncThunk<
  void,
  GetInitOverlaysProps,
  { state: RootState } & ThunkConfig
  // eslint-disable-next-line consistent-return
>('appInit/getInitOverlays', async ({ overlaysId }, { dispatch, getState }) => {
  try {
    const state = getState();

    overlaysId.forEach(id => {
      const userOverlay = userOverlaySelector(state, id);
      const overlay = overlaySelector(state, id);
      const eventData = userOverlay || overlay;

      if (eventData) {
        PluginsEventBus.dispatchEvent('onShowOverlay', eventData);
      }

      dispatch(getOverlayBioEntityForAllModels({ overlayId: id }));
    });
  } catch (error) {
    return Promise.reject(getError({ error, prefix: INIT_OVERLAYS_ERROR_PREFIX }));
  }
});
