import { axiosInstance } from '@/services/api/utils/axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { loginSchema } from '@/models/loginSchema';
import { sessionSchemaValid } from '@/models/sessionValidSchema';
import { Login, SessionValid, User, UserPrivilege } from '@/types/models';
import { USER_ROLE } from '@/constants/user';
import { getError } from '@/utils/error-report/getError';
import axios, { HttpStatusCode } from 'axios';
import { showToast } from '@/utils/showToast';
import { setLoginForOldMinerva } from '@/utils/setLoginForOldMinerva';
import { ThunkConfig } from '@/types/store';
import { userSchema } from '@/models/userSchema';
import { hasPrivilege } from './user.utils';
import { closeModal, openLoggedInMenuModal } from '../modal/modal.slice';
import { apiPath } from '../apiPath';

const getUserRole = (privileges: UserPrivilege[]): string => {
  if (hasPrivilege(privileges, 'IS_ADMIN')) {
    return USER_ROLE.ADMIN;
  }
  if (hasPrivilege(privileges, 'IS_CURATOR')) {
    return USER_ROLE.CURATOR;
  }

  return USER_ROLE.USER;
};

const getUserData = async (login: string): Promise<User> => {
  const response = await axiosInstance.get<User>(apiPath.user(login), {
    withCredentials: true,
  });

  return response.data;
};

export const login = createAsyncThunk(
  'user/login',
  async (credentials: { login: string; password: string }, { dispatch }) => {
    try {
      const searchParams = new URLSearchParams(credentials);
      const { data } = await axiosInstance.post<Login>(apiPath.postLogin(), searchParams, {
        withCredentials: true,
      });

      const isDataValid = validateDataUsingZodSchema(data, loginSchema);
      const loginName = data.login;

      if (isDataValid) {
        setLoginForOldMinerva(loginName);

        const userData = await getUserData(loginName);
        const role = getUserRole(userData.privileges);

        if (role !== USER_ROLE.ADMIN && role !== USER_ROLE.CURATOR) {
          dispatch(closeModal());
        } else {
          dispatch(openLoggedInMenuModal());
        }

        return {
          login: loginName,
          role,
          userData,
          token: data.token,
        };
      }

      return undefined;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === HttpStatusCode.Unauthorized) {
          showToast({
            type: 'error',
            message: 'Invalid credentials.',
          });
          return undefined;
        }
      }
      return Promise.reject(getError({ error, prefix: 'Login' }));
    }
  },
);

export const getSessionValid = createAsyncThunk('user/getSessionValid', async () => {
  const response = await axiosInstance.get<SessionValid>(apiPath.getSessionValid(), {
    withCredentials: true,
  });

  const isDataValid = validateDataUsingZodSchema(response.data, sessionSchemaValid);

  const {
    data: { login: loginName, token },
  } = response;

  const userData = await getUserData(loginName);
  const role = getUserRole(userData.privileges);

  if (isDataValid) {
    setLoginForOldMinerva(loginName);

    return {
      login: loginName,
      userData,
      role,
      token,
    };
  }

  return undefined;
});

export const logout = createAsyncThunk('user/logout', async () => {
  try {
    await axiosInstance.post(apiPath.logout(), null, {
      withCredentials: true,
    });

    setLoginForOldMinerva(undefined);
    return undefined;
  } catch (error) {
    return Promise.reject(getError({ error, prefix: 'Log out' }));
  }
});

export const updateUser = createAsyncThunk<undefined, User, ThunkConfig>(
  'users/updateUser',
  // eslint-disable-next-line consistent-return
  async user => {
    try {
      const newUser = await axiosInstance.patch<User>(
        apiPath.updateUser(user.login),
        {
          user: {
            termsOfUseConsent: user.termsOfUseConsent,
          },
        },
        {
          withCredentials: true,
        },
      );

      validateDataUsingZodSchema(newUser.data, userSchema);

      showToast({ type: 'success', message: 'ToS agreement registered' });
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);
