import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { MapOverlay, OverlayGroup, PageOf } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { pageableSchema } from '@/models/pageableSchema';
import { overlayGroupSchema } from '@/models/overlayGroupSchema';
import { showToast } from '@/utils/showToast';
import axios from 'axios';
import { closeModal } from '@/redux/modal/modal.slice';
import { getAllUserOverlaysByCreator } from '@/redux/overlays/overlays.thunks';
import { z } from 'zod';
import { apiPath } from '../apiPath';

export const getOverlayGroups = createAsyncThunk<OverlayGroup[], void, ThunkConfig>(
  'overlayGroup/getOverlayGroups',
  async () => {
    try {
      const response = await axiosInstanceNewAPI.get<PageOf<OverlayGroup>>(
        apiPath.getOverlayGroups(),
        {
          withCredentials: true,
        },
      );

      const isDataValid = validateDataUsingZodSchema(
        response.data,
        pageableSchema(overlayGroupSchema),
      );

      return isDataValid
        ? response.data.content.sort((groupA, groupB) => groupA.order - groupB.order)
        : [];
    } catch (error) {
      return Promise.reject(getError({ error, prefix: 'Failed to fetch overlay groups' }));
    }
  },
);

type AddOverlayGroupArgs = {
  name: string;
  order: number;
};

export const addOverlayGroup = createAsyncThunk<undefined, AddOverlayGroupArgs, ThunkConfig>(
  'overlayGroups/addOverlayGroup',
  async (
    { name, order },
    { dispatch },
    // eslint-disable-next-line consistent-return
  ) => {
    try {
      const response = await axiosInstanceNewAPI.post<MapOverlay>(
        apiPath.addOverlayGroup(),
        {
          name,
          order,
        },
        {
          withCredentials: true,
        },
      );

      const isDataValid = validateDataUsingZodSchema(response.data, overlayGroupSchema);
      if (!isDataValid) {
        showToast({
          type: 'error',
          message: 'Problem with adding group encountered',
          duration: 120000,
        });
      } else {
        showToast({ type: 'success', message: 'Overlay group added successfully' });
      }
      await dispatch(getOverlayGroups());
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === 'ERR_BAD_REQUEST') {
        const data = error.response?.data;
        showToast({ type: 'error', message: data.reason, duration: 120000 });
      } else {
        return Promise.reject(getError({ error, prefix: 'Failed to add overlay group' }));
      }
    }
  },
);

export const removeOverlayGroup = createAsyncThunk<
  undefined,
  { overlayGroupId: number },
  ThunkConfig
>(
  'overlayGroups/removeOverlayGroup',
  // eslint-disable-next-line consistent-return
  async ({ overlayGroupId }, { dispatch }) => {
    try {
      await axiosInstanceNewAPI.delete(apiPath.removeOverlayGroup(overlayGroupId), {
        withCredentials: true,
      });
      await dispatch(getAllUserOverlaysByCreator());
      await dispatch(getOverlayGroups());
      dispatch(closeModal());

      showToast({ type: 'success', message: 'User overlay group removed successfully' });
    } catch (error) {
      return Promise.reject(getError({ error, prefix: 'Failed to remove user overlay group' }));
    }
  },
);

export const updateOverlayGroups = createAsyncThunk<undefined, OverlayGroup[], ThunkConfig>(
  'overlayGroups/updateOverlayGroups',
  // eslint-disable-next-line consistent-return
  async userOverlayGroups => {
    try {
      const userOverlaysPromises = userOverlayGroups.map(overlayGroup => {
        if (overlayGroup.id !== null)
          return axiosInstanceNewAPI.put<OverlayGroup>(
            apiPath.updateOverlayGroup(overlayGroup.id),
            overlayGroup,
            {
              withCredentials: true,
            },
          );
        return Promise.resolve({ data: overlayGroup });
      });

      const overlayGroupsResponses = await Promise.all(userOverlaysPromises);

      const updatedUserOverlayGroups = overlayGroupsResponses.map(
        updatedUserOverlay => updatedUserOverlay.data,
      );

      validateDataUsingZodSchema(updatedUserOverlayGroups, z.array(overlayGroupSchema));

      showToast({ type: 'success', message: 'User overlay group updated successfully' });
    } catch (error) {
      return Promise.reject(getError({ error, prefix: 'Failed to update user overlay group' }));
    }
  },
);
