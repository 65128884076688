import { UserPrivilege } from '@/types/models';

export const hasPrivilege = (privileges: UserPrivilege[], privilegeType: string): boolean => {
  return privileges.some(privilege => privilege.privilegeType === privilegeType);
};

export const hasPrivilegeToObject = (
  privileges: UserPrivilege[],
  privilegeType: string,
  objectId: string,
): boolean => {
  return Boolean(
    privileges.find(
      privilege => privilege.privilegeType === privilegeType && privilege.objectId === objectId,
    ),
  );
};
