interface MoveBackIconProps {
  className?: string;
}

export const MoveBackIcon = ({ className }: MoveBackIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
  >
    <rect x="32" y="32" width="64" height="64" rx="10" ry="10" strokeWidth="8" fill="white" />
    <rect
      x="4"
      y="4"
      width="68"
      height="68"
      rx="12"
      ry="12"
      strokeWidth="16"
      stroke="white"
      fill="none"
    />
    <rect x="4" y="4" width="64" height="64" rx="10" ry="10" strokeWidth="8" />
  </svg>
);
