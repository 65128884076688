import { LayerLine } from '@/types/models';
import { LayersDictState } from '@/redux/layers/layers.types';

export default function setLayerLine({
  layerId,
  layers,
  layerLine,
}: {
  layerId: number;
  layers: LayersDictState;
  layerLine: LayerLine;
}): void {
  const layer = layers[layerId];
  if (!layer) {
    return;
  }
  layer.lines[layerLine.id] = layerLine;
}
