import { createSlice } from '@reduxjs/toolkit';
import { getSessionValidReducer, loginReducer, logoutReducer } from './user.reducers';
import { UserState } from './user.types';

export const initialState: UserState = {
  loading: 'idle',
  authenticated: false,
  error: { name: '', message: '' },
  login: null,
  role: null,
  userData: null,
  token: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    loginReducer(builder);
    getSessionValidReducer(builder);
    logoutReducer(builder);
  },
});

export default userSlice.reducer;
