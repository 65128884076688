import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import type { RootState } from '../store';

export const dispatchPluginsEvents = (searchQueries: string[], state: RootState): void => {
  const searchModelElements = state.modelElements.search.data;
  const modelElementsResults = searchModelElements
    .map(searchElement => (searchElement.data ? searchElement.data : []))
    .map(data => {
      return data.map(result => {
        return {
          perfect: result.perfect,
          bioEntity: result.modelElement,
        };
      });
    });

  const drugs = state.drugs.data;
  const drugsResults = drugs.map(drug => (drug.data ? drug.data : []));

  const chemicals = state.chemicals.data;
  const chemicalsResults = chemicals.map(chemical => (chemical.data ? chemical.data : []));

  PluginsEventBus.dispatchEvent('onSearch', {
    type: 'bioEntity',
    searchValues: searchQueries,
    results: modelElementsResults,
  });
  PluginsEventBus.dispatchEvent('onSearch', {
    type: 'drugs',
    searchValues: searchQueries,
    results: drugsResults,
  });
  PluginsEventBus.dispatchEvent('onSearch', {
    type: 'chemicals',
    searchValues: searchQueries,
    results: chemicalsResults,
  });
};
