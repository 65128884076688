/* eslint-disable no-magic-numbers */
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  getLayer,
  getLayerImage,
  getLayersForModel,
  getLayerText,
} from '@/redux/layers/layers.thunks';
import { LayersState } from '@/redux/layers/layers.types';
import {
  LAYER_STATE_DEFAULT_DATA,
  LAYERS_STATE_INITIAL_LAYER_MOCK,
} from '@/redux/layers/layers.mock';
import { DEFAULT_ERROR } from '@/constants/errors';
import { LayerImage, LayerLine, LayerOval, LayerRect, LayerText } from '@/types/models';
import setLayerRect from '@/redux/layers/utils/setLayerRect';
import setLayerOval from '@/redux/layers/utils/setLayerOval';
import setLayerLine from '@/redux/layers/utils/setLayerLine';

export const getLayersForModelReducer = (builder: ActionReducerMapBuilder<LayersState>): void => {
  builder.addCase(getLayersForModel.pending, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'pending';
    } else {
      state[modelId] = { ...LAYERS_STATE_INITIAL_LAYER_MOCK, loading: 'pending' };
    }
  });
  builder.addCase(getLayersForModel.fulfilled, (state, action) => {
    const modelId = action.meta.arg;
    const data = action.payload || { ...LAYER_STATE_DEFAULT_DATA };
    if (state[modelId]) {
      state[modelId].data = data;
      state[modelId].loading = 'succeeded';
    } else {
      state[modelId] = { data, loading: 'pending', error: DEFAULT_ERROR };
    }
  });
  builder.addCase(getLayersForModel.rejected, (state, action) => {
    const modelId = action.meta.arg;
    if (state[modelId]) {
      state[modelId].loading = 'failed';
    } else {
      state[modelId] = { ...LAYERS_STATE_INITIAL_LAYER_MOCK, loading: 'failed' };
    }
  });
};

export const getLayerReducer = (builder: ActionReducerMapBuilder<LayersState>): void => {
  builder.addCase(getLayer.fulfilled, (state, action) => {
    const { modelId, layerId } = action.meta.arg;
    if (!state[modelId]) {
      return;
    }
    const { data } = state[modelId];
    const updatedLayerDetails = action.payload;
    if (!data || !updatedLayerDetails) {
      return;
    }
    const layer = data.layers[layerId];
    if (layer) {
      layer.details = updatedLayerDetails;
      return;
    }

    data.layers[layerId] = {
      details: updatedLayerDetails,
      texts: {},
      images: {},
      ovals: {},
      rects: {},
      lines: {},
    };
    data.layersVisibility[layerId] = updatedLayerDetails.visible;
    if (!updatedLayerDetails.locked) {
      data.activeLayers.push(updatedLayerDetails.id);
    }
  });
};

export const layerDeleteReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number }>,
): void => {
  const { modelId, layerId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  delete data.layersVisibility[layerId];
  delete data.layers[layerId];
  const activeLayerIndex = data.activeLayers.findIndex(activeLayerId => activeLayerId === layerId);
  if (activeLayerIndex > -1) {
    data.activeLayers.splice(activeLayerIndex, 1);
  }
};

export const getLayerImageReducer = (builder: ActionReducerMapBuilder<LayersState>): void => {
  builder.addCase(getLayerImage.fulfilled, (state, action) => {
    const { modelId, layerId } = action.meta.arg;
    const { data } = state[modelId];
    const layerImage = action.payload;
    if (!data || !layerImage) {
      return;
    }
    const layer = data.layers[layerId];
    if (!layer) {
      return;
    }
    layer.images[layerImage.id] = layerImage;
  });
};

export const getLayerTextReducer = (builder: ActionReducerMapBuilder<LayersState>): void => {
  builder.addCase(getLayerText.fulfilled, (state, action) => {
    const { modelId, layerId } = action.meta.arg;
    const { data } = state[modelId];
    const layerText = action.payload;
    if (!data || !layerText) {
      return;
    }
    const layer = data.layers[layerId];
    if (!layer) {
      return;
    }
    layer.texts[layerText.id] = layerText;
  });
};

export const setLayerVisibilityReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; visible: boolean; layerId: number }>,
): void => {
  const { modelId, visible, layerId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  if (data.layersVisibility[layerId] !== undefined) {
    data.layersVisibility[layerId] = visible;
  }
};

export const setLayerToInactiveReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number }>,
): void => {
  const { modelId, layerId } = action.payload;
  if (!state[modelId]) {
    return;
  }
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const index = data.activeLayers.findIndex(activeLayerId => activeLayerId === layerId);
  if (index !== -1) {
    data.activeLayers.splice(index, 1);
  }
};

export const setLayerToActiveReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number }>,
): void => {
  const { modelId, layerId } = action.payload;
  if (!state[modelId]) {
    return;
  }
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.activeLayers.find(activeLayerId => activeLayerId === layerId);
  if (!layer) {
    data.activeLayers.push(layerId);
  }
};

export const setDrawLayerReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number | null }>,
): void => {
  const { modelId, layerId } = action.payload;
  if (!state[modelId]) {
    return;
  }
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  data.drawLayer = layerId;
};

export const layerAddImageReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerImage: LayerImage }>,
): void => {
  const { modelId, layerId, layerImage } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  layer.images[layerImage.id] = layerImage;
};

export const layerUpdateImageReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerImage: LayerImage }>,
): void => {
  const { modelId, layerId, layerImage } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  layer.images[layerImage.id] = layerImage;
};

export const layerDeleteImageReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; imageId: number }>,
): void => {
  const { modelId, layerId, imageId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  delete layer.images[imageId];
};

export const layerAddTextReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerText: LayerText }>,
): void => {
  const { modelId, layerId, layerText } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  layer.texts[layerText.id] = layerText;
};

export const layerUpdateTextReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerText: LayerText }>,
): void => {
  const { modelId, layerId, layerText } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  layer.texts[layerText.id] = layerText;
};

export const layerDeleteTextReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; textId: number }>,
): void => {
  const { modelId, layerId, textId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  delete layer.texts[textId];
};

export const layerAddRectReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerRect: LayerRect }>,
): void => {
  const { modelId, layerId, layerRect } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  setLayerRect({ layerId, layers: data.layers, layerRect });
};

export const layerUpdateRectReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerRect: LayerRect }>,
): void => {
  const { modelId, layerId, layerRect } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  setLayerRect({ layerId, layers: data.layers, layerRect });
};

export const layerDeleteRectReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; rectId: number }>,
): void => {
  const { modelId, layerId, rectId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  delete layer.rects[rectId];
};

export const layerAddOvalReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerOval: LayerOval }>,
): void => {
  const { modelId, layerId, layerOval } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  setLayerOval({ layerId, layers: data.layers, layerOval });
};

export const layerUpdateOvalReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerOval: LayerOval }>,
): void => {
  const { modelId, layerId, layerOval } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  setLayerOval({ layerId, layers: data.layers, layerOval });
};

export const layerDeleteOvalReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; ovalId: number }>,
): void => {
  const { modelId, layerId, ovalId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  delete layer.ovals[ovalId];
};

export const layerAddLineReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerLine: LayerLine }>,
): void => {
  const { modelId, layerId, layerLine } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  setLayerLine({ layerId, layers: data.layers, layerLine });
};

export const layerUpdateLineReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; layerLine: LayerLine }>,
): void => {
  const { modelId, layerId, layerLine } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  setLayerLine({ layerId, layers: data.layers, layerLine });
};

export const layerDeleteLineReducer = (
  state: LayersState,
  action: PayloadAction<{ modelId: number; layerId: number; lineId: number }>,
): void => {
  const { modelId, layerId, lineId } = action.payload;
  const { data } = state[modelId];
  if (!data) {
    return;
  }
  const layer = data.layers[layerId];
  if (!layer) {
    return;
  }
  delete layer.lines[lineId];
};
