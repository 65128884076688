interface OvalIconProps {
  className?: string;
}

export const OvalIcon = ({ className }: OvalIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 100 100"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="50" cy="50" rx="46" ry="28" stroke="black" fill="none" strokeWidth="4.5" />
  </svg>
);
