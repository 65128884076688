interface LayerArrowDownIconProps {
  className?: string;
}

export const LayerArrowDownIcon = ({ className }: LayerArrowDownIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="2 3 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M12 21L7 16H10V10H14V16H17L12 21Z" />
    <rect x="4" y="3" width="16" height="2" />
    <rect x="4" y="7" width="16" height="2" />
  </svg>
);
