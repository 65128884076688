import { JSX } from 'react';

interface CrossedEyeIconProps {
  className?: string;
}

export const CrossedEyeIcon = ({ className }: CrossedEyeIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="1.5"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12s4-7 10-7 10 7 10 7-4 7-10 7-10-7-10-7z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12" cy="12" r="3" fill="none" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="3" y1="3" x2="21" y2="21" fill="none" />
  </svg>
);
