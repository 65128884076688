import { createSlice } from '@reduxjs/toolkit';
import {
  clearSearchModelElementsReducer,
  getModelElementsReducer,
  setModelElementSearchReducer,
  setMultipleModelElementSearchReducer,
  searchModelElementReducer,
  searchMultiModelElementsReducer,
} from '@/redux/modelElements/modelElements.reducers';
import { MODEL_ELEMENTS_INITIAL_STATE_MOCK } from '@/redux/modelElements/modelElements.mock';

export const modelElements = createSlice({
  name: 'modelElements',
  initialState: MODEL_ELEMENTS_INITIAL_STATE_MOCK,
  reducers: {
    setModelElementSearch: setModelElementSearchReducer,
    setMultipleModelElementSearch: setMultipleModelElementSearchReducer,
    clearSearchModelElements: clearSearchModelElementsReducer,
  },
  extraReducers: builder => {
    searchModelElementReducer(builder);
    searchMultiModelElementsReducer(builder);
    getModelElementsReducer(builder);
  },
});

export const { setModelElementSearch, setMultipleModelElementSearch, clearSearchModelElements } =
  modelElements.actions;

export default modelElements.reducer;
