import { twMerge } from 'tailwind-merge';
import { Icon } from '../Icon';

type ToastArgs = {
  type: 'success' | 'error' | 'info';
  message: string;
  onDismiss: () => void;
};

const textColors = {
  error: 'text-red-500',
  success: 'text-green-500',
  info: 'text-blue-500',
};

const bgColors = {
  error: 'before:bg-red-500',
  success: 'before:bg-green-500',
  info: 'before:bg-blue-500',
};

export const Toast = ({ type, message, onDismiss }: ToastArgs): React.ReactNode => (
  <div
    className={twMerge(
      'flex h-[76px] w-[700px] items-center rounded-l rounded-r-lg bg-white p-4 drop-shadow before:absolute before:inset-y-0 before:left-0 before:block before:w-1 before:rounded-l-lg before:content-[""]',
      bgColors[type],
    )}
  >
    <p
      className={twMerge('h-full overflow-y-auto text-base font-bold', textColors[type])}
      dangerouslySetInnerHTML={{ __html: message }}
    />

    <button type="button" onClick={onDismiss} className="ml-auto flex-none">
      <Icon name="close" className="ml-3 h-7 w-7 fill-font-500" />
    </button>
  </div>
);
