import { z } from 'zod';

export const layerImageSchema = z.object({
  id: z.number(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  layer: z.number(),
  glyph: z.number().nullable(),
});
