interface CenterIconProps {
  className?: string;
}

export const CenterIcon = ({ className }: CenterIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
  >
    <circle cx="50" cy="50" r="45" strokeWidth="6" fill="none" />
    <line x1="50" y1="5" x2="50" y2="30" strokeWidth="6" />
    <line x1="50" y1="70" x2="50" y2="95" strokeWidth="6" />
    <line x1="5" y1="50" x2="30" y2="50" strokeWidth="6" />
    <line x1="65" y1="50" x2="95" y2="50" strokeWidth="6" />
  </svg>
);
