import { createSlice } from '@reduxjs/toolkit';
import { BIOENTITY_INITIAL_STATE } from './bioEntity.constants';
import { toggleIsContentTabOpenedReducer } from './bioEntity.reducers';

export const bioEntityContentsSlice = createSlice({
  name: 'bioEntityContents',
  initialState: BIOENTITY_INITIAL_STATE,
  reducers: {
    toggleIsContentTabOpened: toggleIsContentTabOpenedReducer,
  },
});

export const { toggleIsContentTabOpened } = bioEntityContentsSlice.actions;

export default bioEntityContentsSlice.reducer;
