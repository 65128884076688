import { LayersState, LayersVisibilitiesState } from '@/redux/layers/layers.types';
import { DEFAULT_ERROR } from '@/constants/errors';
import { FetchDataState } from '@/types/fetchDataState';

export const LAYERS_STATE_INITIAL_MOCK: LayersState = {};

export const LAYER_STATE_DEFAULT_DATA: LayersVisibilitiesState = {
  layers: {},
  layersVisibility: {},
  activeLayers: [],
  drawLayer: null,
};

export const LAYERS_STATE_INITIAL_LAYER_MOCK: FetchDataState<LayersVisibilitiesState> = {
  data: {
    ...LAYER_STATE_DEFAULT_DATA,
  },
  loading: 'idle',
  error: DEFAULT_ERROR,
};
