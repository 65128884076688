import { ModelsState } from '@/redux/models/models.types';
import { createSlice } from '@reduxjs/toolkit';
import { getModelsReducer } from './models.reducers';

const initialState: ModelsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getModelsReducer(builder);
  },
});

export default modelsSlice.reducer;
