import { DEFAULT_ZOOM } from '@/constants/map';
import { MAP_DATA_INITIAL_STATE } from '@/redux/map/map.constants';
import { GetUpdatedMapDataResult, MapData } from '@/redux/map/map.types';
import { MapModel } from '@/types/models';
import { DeepPartial } from '@reduxjs/toolkit';
import MapBackgroundsEnum from '@/redux/map/map.enums';
import { getPointMerged } from '../object/getPointMerged';

interface GetUpdatedMapDataArgs {
  model: MapModel;
  position?: DeepPartial<MapData['position']>;
  background?: MapBackgroundsEnum;
}

const HALF = 2;

export const getUpdatedMapData = ({
  model,
  position,
  background,
}: GetUpdatedMapDataArgs): GetUpdatedMapDataResult => {
  const defaultPosition = {
    x: model.defaultCenterX ?? model.width / HALF,
    y: model.defaultCenterY ?? model.height / HALF,
    z: model.defaultZoomLevel ?? DEFAULT_ZOOM,
  };

  const mergedPosition = getPointMerged(position?.initial || {}, defaultPosition);

  return {
    modelId: model.id,
    backgroundId: background || MAP_DATA_INITIAL_STATE.backgroundId,
    size: {
      width: model.width,
      height: model.height,
      tileSize: model.tileSize,
      minZoom: model.minZoom,
      maxZoom: model.maxZoom,
    },
    position: {
      initial: mergedPosition,
      last: mergedPosition,
    },
  };
};
