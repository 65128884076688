import { MOL_ART_UNIPROT_ID_DEFAULT, OVERVIEW_IMAGE_ID_DEFAULT } from '../map/map.constants';
import { ModalState } from './modal.types';

export const MODAL_INITIAL_STATE: ModalState = {
  isOpen: false,
  modalName: 'none',
  modalTitle: '',
  overviewImagesState: {
    imageId: OVERVIEW_IMAGE_ID_DEFAULT,
  },
  molArtState: {
    uniprotId: MOL_ART_UNIPROT_ID_DEFAULT,
  },
  editOverlayState: null,
  editOverlayGroupState: null,
  errorReportState: {},
  layerFactoryState: { id: undefined },
  layerObjectFactoryState: undefined,
  layerLineFactoryState: undefined,
  elementGlyphState: undefined,
};
