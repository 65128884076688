import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';

export const layerOvalSchema = z.object({
  id: z.number().int().positive(),
  layer: z.number(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  lineWidth: z.number(),
  color: colorSchema,
  borderColor: colorSchema,
});
