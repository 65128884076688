interface LayerArrowUpIconProps {
  className?: string;
}

export const LayerArrowUpIcon = ({ className }: LayerArrowUpIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="2 3 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M12 3L7 8H10V14H14V8H17L12 3Z" />
    <rect x="4" y="16" width="16" height="2" />
    <rect x="4" y="20" width="16" height="2" />
  </svg>
);
