import { PROJECT_ID } from '@/constants';
import { PerfectSearchParams } from '@/types/search';
import { GetPublicationsParams, PublicationsQueryParams } from './publications/publications.types';

const getPublicationsURLSearchParams = (
  providedParams: PublicationsQueryParams,
): URLSearchParams => {
  const params = new URLSearchParams();

  const validProvidedParamsArray = Object.entries(providedParams).filter(([, value]) =>
    Boolean(value),
  );

  validProvidedParamsArray.forEach(([key, value]) => {
    params.append(key, value.toString());
  });

  return params;
};

export const apiPath = {
  getBioEntityContentsStringWithQuery: ({
    searchQuery,
    isPerfectMatch,
  }: PerfectSearchParams): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/:search?query=${searchQuery}&size=1000&perfectMatch=${isPerfectMatch}`,
  getDrugsStringWithQuery: (searchQuery: string): string =>
    `projects/${PROJECT_ID}/drugs:search?query=${searchQuery}`,
  getDrugsStringWithColumnsTarget: (columns: string, target: string): string =>
    `projects/${PROJECT_ID}/drugs:search?columns=${columns}&target=${target}`,
  getChemicalsStringWithColumnsTarget: (columns: string, target: string): string =>
    `projects/${PROJECT_ID}/chemicals:search?columns=${columns}&target=${target}`,
  getModelsString: (): string => `projects/${PROJECT_ID}/maps/?size=10000`,
  getModelElements: (modelId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/elements/?size=10000`,
  updateElementGlyph: (modelId: number, elementId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/elements/${elementId}/glyph/`,
  getShapes: (): string => `projects/${PROJECT_ID}/shapes/`,
  getLineTypes: (): string => `projects/${PROJECT_ID}/lineTypes/`,
  getArrowTypes: (): string => `projects/${PROJECT_ID}/arrowTypes/`,
  getLayers: (modelId: number): string => `projects/${PROJECT_ID}/maps/${modelId}/layers/`,
  getLayerTexts: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/texts/`,
  getLayerRects: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/rects/`,
  addLayerRect: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/rects/`,
  getLayerRect: (modelId: number, layerId: number, rectId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/rects/${rectId}`,
  updateLayerRect: (modelId: number, layerId: number, rectId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/rects/${rectId}`,
  removeLayerRect: (modelId: number, layerId: number, rectId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/rects/${rectId}`,
  getLayerOvals: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/ovals/`,
  getLayerOval: (modelId: number, layerId: number, ovalId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/ovals/${ovalId}`,
  addLayerOval: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/ovals/`,
  updateLayerOval: (modelId: number, layerId: number, ovalId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/ovals/${ovalId}`,
  removeLayerOval: (modelId: number, layerId: number, ovalId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/ovals/${ovalId}`,
  getLayerLines: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/lines/`,
  getLayerImages: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/images/`,
  storeLayer: (modelId: number): string => `projects/${PROJECT_ID}/maps/${modelId}/layers/`,
  updateLayer: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}`,
  removeLayer: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}`,
  addLayerImageObject: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/images/`,
  updateLayerImageObject: (modelId: number, layerId: number, imageId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/images/${imageId}`,
  removeLayerImageObject: (modelId: number, layerId: number, imageId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/images/${imageId}`,
  getLayerImageObject: (modelId: number, layerId: number, imageId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/images/${imageId}`,
  addLayerText: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/texts/`,
  getLayerText: (modelId: number, layerId: number, textId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/texts/${textId}`,
  updateLayerText: (modelId: number, layerId: number, textId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/texts/${textId}`,
  removeLayerText: (modelId: number, layerId: number, textId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/texts/${textId}`,
  addLayerLine: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/lines/`,
  getLayerLine: (modelId: number, layerId: number, lineId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/lines/${lineId}`,
  updateLayerLine: (modelId: number, layerId: number, lineId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/lines/${lineId}`,
  removeLayerLine: (modelId: number, layerId: number, lineId: number | string): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}/lines/${lineId}`,
  getLayer: (modelId: number, layerId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/layers/${layerId}`,
  getGlyphImage: (glyphId: number): string =>
    `projects/${PROJECT_ID}/glyphs/${glyphId}/fileContent`,
  getGlyphs: (): string => `projects/${PROJECT_ID}/glyphs/`,
  addGlyph: (): string => `projects/${PROJECT_ID}/glyphs/`,
  getNewReactionsForModel: (modelId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/reactions/?size=10000`,
  getNewReaction: (modelId: number, reactionId: number): string =>
    `projects/${PROJECT_ID}/maps/${modelId}/bioEntities/reactions/${reactionId}`,
  getChemicalsStringWithQuery: (searchQuery: string): string =>
    `projects/${PROJECT_ID}/chemicals:search?query=${searchQuery}`,
  getAllOverlaysByProjectIdQuery: (
    projectId: string,
    { publicOverlay }: { publicOverlay: boolean },
  ): string => `projects/${projectId}/overlays/?isPublic=${String(publicOverlay)}`,
  getProjectById: (projectId: string): string => `projects/${projectId}`,
  getProjects: (): string => `projects/`,
  getSessionValid: (): string => `users/isSessionValid`,
  postLogin: (): string => `doLogin`,
  getConfigurationOptions: (): string => 'configuration/options/',
  getConfiguration: (): string => 'configuration/',
  getOverlayBioEntity: ({ overlayId, modelId }: { overlayId: number; modelId: number }): string =>
    `projects/${PROJECT_ID}/overlays/${overlayId}/models/${modelId}/bioEntities/`,
  createOverlay: (projectId: string): string => `projects/${projectId}/overlays/`,
  createOverlayFile: (): string => `files/`,
  uploadOverlayFileContent: (fileId: number): string => `files/${fileId}:uploadContent`,
  getStatisticsById: (projectId: string): string => `projects/${projectId}/statistics/`,
  downloadNetworkCsv: (): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/reactions/:downloadCsv`,
  getAllUserOverlaysByCreatorQuery: ({
    publicOverlay,
    creator,
  }: {
    publicOverlay: boolean;
    creator: string;
  }): string =>
    `projects/${PROJECT_ID}/overlays/?size=10000&creator=${creator}&isPublic=${String(
      publicOverlay,
    )}`,
  updateOverlay: (overlayId: number): string => `projects/${PROJECT_ID}/overlays/${overlayId}/`,
  removeOverlay: (overlayId: number): string => `projects/${PROJECT_ID}/overlays/${overlayId}/`,
  downloadElementsCsv: (): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/elements/:downloadCsv`,
  downloadOverlay: (overlayId: number): string =>
    `projects/${PROJECT_ID}/overlays/${overlayId}:downloadSource`,
  getSourceFile: (): string => `/projects/${PROJECT_ID}:downloadSource`,
  getMesh: (meshId: string): string => `mesh/${meshId}`,
  getTaxonomy: (taxonomyId: string): string => `taxonomy/${taxonomyId}`,
  getPublications: ({ params, modelId = '*' }: GetPublicationsParams): string =>
    `/projects/${PROJECT_ID}/maps/${modelId}/publications/?${getPublicationsURLSearchParams(
      params,
    )}`,
  registerPluign: (): string => `plugins/`,
  getPlugin: (pluginId: string): string => `plugins/${pluginId}/`,
  getAllPlugins: (): string => `/plugins/`,
  logout: (): string => `doLogout`,
  user: (login: string): string => `users/${login}`,
  updateUser: (login: string): string => `users/${login}`,
  getStacktrace: (code: string): string => `stacktrace/${code}`,
  submitError: (): string => `minervanet/submitError`,
  getOauthProviders: (): string => `oauth/providers/`,
  userPrivileges: (login: string): string => `users/${login}?columns=privileges`,
  getComments: (): string => `projects/${PROJECT_ID}/comments/models/*/`,
  addComment: (modelId: number, x: number, y: number): string =>
    `projects/${PROJECT_ID}/comments/models/${modelId}/points/${x},${y}/`,

  getSearchAutocomplete: (): string =>
    `projects/${PROJECT_ID}/models/*/bioEntities/suggestedQueryList`,
  getDrugAutocomplete: (): string => `projects/${PROJECT_ID}/drugs/suggestedQueryList`,
  getChemicalAutocomplete: (): string => `projects/${PROJECT_ID}/chemicals/suggestedQueryList`,
  getOverlayGroups: (): string => `projects/${PROJECT_ID}/overlay_groups/`,
  addOverlayGroup: (): string => `projects/${PROJECT_ID}/overlay_groups/`,
  removeOverlayGroup: (overlayGroupId: number): string =>
    `projects/${PROJECT_ID}/overlay_groups/${overlayGroupId}/`,
  updateOverlayGroup: (overlayGroupId: number): string =>
    `projects/${PROJECT_ID}/overlay_groups/${overlayGroupId}/`,
};
