interface TrashIconProps {
  className?: string;
}

export const TrashIcon = ({ className }: TrashIconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    width="20"
    height="20"
    className={className}
  >
    <path d="M9 3V4H4V6H5V20C5 21.1 5.9 22 7 22H17C18.1 22 19 21.1 19 20V6H20V4H15V3H9M7 6H17V20H7V6M9 8V18H11V8H9M13 8V18H15V8H13Z" />
  </svg>
);
