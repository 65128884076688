/* eslint-disable no-magic-numbers */
import {
  createdOverlayFileSchema,
  mapOverlaySchema,
  uploadedOverlayFileContentSchema,
} from '@/models/mapOverlaySchema';
import { axiosInstance, axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { CreatedOverlayFile, MapOverlay, OverlayGroup, PageOf } from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { z } from 'zod';
import { PluginsEventBus } from '@/services/pluginsManager/pluginsEventBus';
import { showToast } from '@/utils/showToast';
import { ThunkConfig } from '@/types/store';
import { BASE_API_URL } from '@/constants';
import { getError } from '@/utils/error-report/getError';
import axios from 'axios';
import { pageableSchema } from '@/models/pageableSchema';
import { apiPath } from '../apiPath';
import {
  CHUNK_SIZE,
  OVERLAYS_FETCHING_ERROR_PREFIX,
  USER_OVERLAYS_FETCHING_ERROR_PREFIX,
  USER_OVERLAY_ADD_ERROR_PREFIX,
  USER_OVERLAY_ADD_SUCCESS_MESSAGE,
  USER_OVERLAY_REMOVE_ERROR_PREFIX,
  USER_OVERLAY_REMOVE_SUCCESS_MESSAGE,
  USER_OVERLAY_UPDATE_ERROR_PREFIX,
  USER_OVERLAY_UPDATE_SUCCESS_MESSAGE,
} from './overlays.constants';
import { closeModal } from '../modal/modal.slice';
import type { RootState } from '../store';

export const getAllPublicOverlaysByProjectId = createAsyncThunk<MapOverlay[], string, ThunkConfig>(
  'overlays/getAllPublicOverlaysByProjectId',
  async (projectId: string) => {
    try {
      const response = await axiosInstanceNewAPI.get<PageOf<MapOverlay>>(
        apiPath.getAllOverlaysByProjectIdQuery(projectId, { publicOverlay: true }),
      );

      const isDataValid = validateDataUsingZodSchema(
        response.data,
        pageableSchema(mapOverlaySchema),
      );

      return isDataValid
        ? response.data.content.sort((overlayA, overlayB) => overlayA.order - overlayB.order)
        : [];
    } catch (error) {
      return Promise.reject(getError({ error, prefix: OVERLAYS_FETCHING_ERROR_PREFIX }));
    }
  },
);

export const getAllUserOverlaysByCreator = createAsyncThunk<MapOverlay[], void, ThunkConfig>(
  'overlays/getAllUserOverlaysByCreator',
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const creator = state.user.login;
      if (!creator) return [];

      const response = await axiosInstanceNewAPI<PageOf<MapOverlay>>(
        apiPath.getAllUserOverlaysByCreatorQuery({
          creator,
          publicOverlay: false,
        }),
        {
          withCredentials: true,
        },
      );

      const isDataValid = validateDataUsingZodSchema(
        response.data,
        pageableSchema(mapOverlaySchema),
      );

      const sortByOrder = (userOverlayA: MapOverlay, userOverlayB: MapOverlay): number => {
        if (userOverlayA.order > userOverlayB.order) return 1;
        return -1;
      };

      const sortedUserOverlays = response.data.content
        .sort(sortByOrder)
        .filter(overlay => !overlay.publicOverlay);

      return isDataValid ? sortedUserOverlays : [];
    } catch (error) {
      return Promise.reject(getError({ error, prefix: USER_OVERLAYS_FETCHING_ERROR_PREFIX }));
    }
  },
);

/** UTILS */

type CreateFileArgs = {
  filename: string;
  content: string;
};

const createFile = async ({ filename, content }: CreateFileArgs): Promise<CreatedOverlayFile> => {
  const fileParams = {
    filename: `C:\\fakepath\\${filename}`,
    length: content.length.toString(),
  };

  const response = await axiosInstance.post(
    apiPath.createOverlayFile(),
    new URLSearchParams(fileParams),
    {
      withCredentials: true,
    },
  );

  const isDataValid = validateDataUsingZodSchema(response.data, createdOverlayFileSchema);
  return isDataValid ? response.data : undefined;
};

type UploadContentArgs = {
  createdFile: CreatedOverlayFile;
  overlayContent: string;
};
const uploadContent = async ({ createdFile, overlayContent }: UploadContentArgs): Promise<void> => {
  const data = new Uint8Array(new TextEncoder().encode(overlayContent));
  let uploadedLength = 0;

  const sendChunk = async (): Promise<void> => {
    if (uploadedLength >= data.length) {
      return;
    }

    const chunk = data.slice(uploadedLength, uploadedLength + CHUNK_SIZE);

    const responeJSON = await fetch(
      `${BASE_API_URL}/${apiPath.uploadOverlayFileContent(createdFile.id)}`,
      {
        method: 'POST',
        credentials: 'include',
        body: chunk,
      },
    );

    const response = await responeJSON.json();
    validateDataUsingZodSchema(response, uploadedOverlayFileContentSchema);

    uploadedLength += chunk.length;
    sendChunk();
  };

  await sendChunk();
};

type CreatedOverlayArgs = {
  createdFile: CreatedOverlayFile;
  description: string;
  name: string;
  type: string;
  projectId: string;
  group: OverlayGroup;
};

const creteOverlay = async ({
  createdFile,
  description,
  type,
  name,
  projectId,
  group,
}: CreatedOverlayArgs): Promise<MapOverlay | undefined> => {
  const data = {
    name,
    description,
    filename: createdFile.filename,
    colorSchemaType: type,
    fileId: createdFile.id.toString(),
    group: group.id,
    public: false,
    orderIndex: 1,
  };

  const response = await axiosInstanceNewAPI.post<MapOverlay>(
    apiPath.createOverlay(projectId),
    data,
    {
      withCredentials: true,
    },
  );

  PluginsEventBus.dispatchEvent('onAddDataOverlay', response.data);

  const isDataValid = validateDataUsingZodSchema(response.data, mapOverlaySchema);

  return isDataValid ? response.data : undefined;
};

type AddOverlayArgs = {
  filename: string;
  content: string;
  description: string;
  type: string;
  name: string;
  projectId: string;
  group: OverlayGroup;
};

export const addOverlay = createAsyncThunk<undefined, AddOverlayArgs, ThunkConfig>(
  'overlays/addOverlay',
  async (
    { filename, content, description, name, type, projectId, group },
    { dispatch },
    // eslint-disable-next-line consistent-return
  ) => {
    try {
      const createdFile = await createFile({
        filename,
        content,
      });

      await uploadContent({
        createdFile,
        overlayContent: content,
      });

      await creteOverlay({
        createdFile,
        description,
        name,
        type,
        projectId,
        group,
      });

      await dispatch(getAllUserOverlaysByCreator());

      showToast({ type: 'success', message: USER_OVERLAY_ADD_SUCCESS_MESSAGE });
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === 'ERR_BAD_REQUEST') {
        const data = error.response?.data;
        showToast({ type: 'error', message: data.reason, duration: 120000 });
      } else {
        return Promise.reject(getError({ error, prefix: USER_OVERLAY_ADD_ERROR_PREFIX }));
      }
    }
  },
);

export const updateOverlays = createAsyncThunk<undefined, MapOverlay[], ThunkConfig>(
  'overlays/updateOverlays',
  // eslint-disable-next-line consistent-return
  async userOverlays => {
    try {
      const userOverlaysPromises = userOverlays.map(userOverlay =>
        axiosInstanceNewAPI.put<MapOverlay>(
          apiPath.updateOverlay(userOverlay.id),
          {
            ...userOverlay,
            orderIndex: userOverlay.order,
            colorSchemaType: userOverlay.type,
            public: userOverlay.publicOverlay,
          },
          {
            withCredentials: true,
          },
        ),
      );

      const userOverlaysResponses = await Promise.all(userOverlaysPromises);

      const updatedUserOverlays = userOverlaysResponses.map(
        updatedUserOverlay => updatedUserOverlay.data,
      );

      validateDataUsingZodSchema(updatedUserOverlays, z.array(mapOverlaySchema));

      showToast({ type: 'success', message: USER_OVERLAY_UPDATE_SUCCESS_MESSAGE });
    } catch (error) {
      return Promise.reject(getError({ error, prefix: USER_OVERLAY_UPDATE_ERROR_PREFIX }));
    }
  },
);

export const removeOverlay = createAsyncThunk<undefined, { overlayId: number }, ThunkConfig>(
  'overlays/removeOverlay',
  // eslint-disable-next-line consistent-return
  async ({ overlayId }, { dispatch }) => {
    try {
      await axiosInstance.delete(apiPath.removeOverlay(overlayId), {
        withCredentials: true,
      });

      PluginsEventBus.dispatchEvent('onRemoveDataOverlay', overlayId);
      await dispatch(getAllUserOverlaysByCreator());
      dispatch(closeModal());

      showToast({ type: 'success', message: USER_OVERLAY_REMOVE_SUCCESS_MESSAGE });
    } catch (error) {
      return Promise.reject(getError({ error, prefix: USER_OVERLAY_REMOVE_ERROR_PREFIX }));
    }
  },
);
