interface EditIconProps {
  className?: string;
}

export const EditIcon = ({ className }: EditIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2 6.4L12 15.6C11.2 16.4 8.8 16.8 8.3 16.3C7.8 15.8 8.2 13.4 9 12.6L18.4 3.2C18.6 3 18.8 2.8 19.1 2.7C19.4 2.5 19.7 2.4 20.1 2.4C20.4 2.4 20.7 2.5 21 2.7C21.3 2.9 21.5 3.1 21.7 3.4C21.9 3.7 22 4 22 4.4C22 4.7 21.9 5 21.7 5.3C21.5 5.6 21.3 5.8 21.2 6L21.2 6.4Z"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="miter"
      fill="none"
    />
    <path
      d="M11 4H6C5 4 4.2 4.4 3.6 5C3 5.6 2.6 6.4 2.6 8V18C2.6 19 3 19.8 3.6 20.4C4.2 21 5 21.4 6 21.4H17C18.8 21.4 19.6 20.4 19.6 18V13"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="miter"
      fill="none"
    />
  </svg>
);
