import { createSlice } from '@reduxjs/toolkit';

import { OverlayGroupsState } from '@/redux/overlayGroup/overlayGroup.types';
import { getOverlayGroupsReducer } from '@/redux/overlayGroup/overlayGroup.reducers';

const initialState: OverlayGroupsState = {
  data: [],
  loading: 'idle',
  error: { name: '', message: '' },
};

const overlayGroupsSlice = createSlice({
  name: 'overlayGroup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getOverlayGroupsReducer(builder);
  },
});

export default overlayGroupsSlice.reducer;
