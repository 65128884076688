interface ArrowDoubleUpIconProps {
  className?: string;
}

export const ArrowDoubleUpIcon = ({ className }: ArrowDoubleUpIconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16L8 6M8 6L5 9M8 6L11 9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 16L16 6M16 6L13 9M16 6L19 9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="4" y1="18" x2="20" y2="18" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
