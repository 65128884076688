/* eslint-disable no-magic-numbers */
import { z as zod } from 'zod';
import { apiPath } from '@/redux/apiPath';
import {
  Color,
  Layer,
  LayerImage,
  LayerLine,
  LayerOval,
  LayerRect,
  Layers,
  LayerText,
} from '@/types/models';
import { validateDataUsingZodSchema } from '@/utils/validateDataUsingZodSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/types/store';
import { getError } from '@/utils/error-report/getError';
import { axiosInstanceNewAPI } from '@/services/api/utils/axiosInstance';
import { layerSchema } from '@/models/layerSchema';
import { LAYERS_FETCHING_ERROR_PREFIX } from '@/redux/layers/layers.constants';
import {
  LayerState,
  LayerStoreInterface,
  LayersVisibilitiesState,
  LayerUpdateInterface,
} from '@/redux/layers/layers.types';
import { layerTextSchema } from '@/models/layerTextSchema';
import { layerRectSchema } from '@/models/layerRectSchema';
import { pageableSchema } from '@/models/pageableSchema';
import { layerOvalSchema } from '@/models/layerOvalSchema';
import { layerLineSchema } from '@/models/layerLineSchema';
import { layerImageSchema } from '@/models/layerImageSchema';
import arrayToKeyValue from '@/utils/array/arrayToKeyValue';
import { LayerTextFactoryForm } from '@/components/FunctionalArea/Modal/LayerTextFactoryModal/LayerTextFactory.types';
import {
  BoundingBox,
  HorizontalAlign,
  VerticalAlign,
} from '@/components/Map/MapViewer/MapViewer.types';
import { LayerRectFactoryForm } from '@/components/FunctionalArea/Modal/LayerRectFactoryModal/LayerRectFactory.types';
import { LayerOvalFactoryForm } from '@/components/FunctionalArea/Modal/LayerOvalFactoryModal/LayerOvalFactory.types';
import {
  LayerLineEditFactoryPayload,
  LayerLineFactoryPayload,
} from '@/components/FunctionalArea/Modal/LayerLineFactoryModal/LayerLineFactory.types';

export const getLayer = createAsyncThunk<
  Layer | null,
  { modelId: number; layerId: number },
  ThunkConfig
>('layers/getLayer', async ({ modelId, layerId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<Layer>(apiPath.getLayer(modelId, layerId));

    const isDataValid = validateDataUsingZodSchema(data, layerSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const getLayersForModel = createAsyncThunk<
  LayersVisibilitiesState | undefined,
  number,
  ThunkConfig
>('layers/getLayers', async (modelId: number) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<Layers>(apiPath.getLayers(modelId));
    const isDataValid = validateDataUsingZodSchema(data, pageableSchema(layerSchema));
    if (!isDataValid) {
      return undefined;
    }
    let layers = await Promise.all(
      data.content.map(async (layer: Layer) => {
        const [textsResponse, rectsResponse, ovalsResponse, linesResponse, imagesResponse] =
          await Promise.all([
            axiosInstanceNewAPI.get(apiPath.getLayerTexts(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerRects(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerOvals(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerLines(modelId, layer.id)),
            axiosInstanceNewAPI.get(apiPath.getLayerImages(modelId, layer.id)),
          ]);
        return {
          details: layer,
          texts: arrayToKeyValue(textsResponse.data.content as Array<LayerText>, 'id'),
          rects: arrayToKeyValue(rectsResponse.data.content as Array<LayerRect>, 'id'),
          ovals: arrayToKeyValue(ovalsResponse.data.content as Array<LayerOval>, 'id'),
          lines: arrayToKeyValue(linesResponse.data.content as Array<LayerLine>, 'id'),
          images: arrayToKeyValue(imagesResponse.data.content as Array<LayerImage>, 'id'),
        };
      }),
    );
    layers = layers.filter(layer => {
      return (
        zod.array(layerTextSchema).safeParse(Object.values(layer.texts)).success &&
        zod.array(layerRectSchema).safeParse(Object.values(layer.rects)).success &&
        zod.array(layerOvalSchema).safeParse(Object.values(layer.ovals)).success &&
        zod.array(layerLineSchema).safeParse(Object.values(layer.lines)).success &&
        zod.array(layerImageSchema).safeParse(Object.values(layer.images)).success
      );
    });
    const layersVisibility = layers.reduce((acc: { [key: string]: boolean }, layer) => {
      acc[layer.details.id] = layer.details.visible;
      return acc;
    }, {});
    const activeLayers = layers
      .filter(layer => !layer.details.locked)
      .map(layer => layer.details.id);
    const layersDict = layers.reduce((acc: { [key: string]: LayerState }, layer) => {
      acc[layer.details.id] = layer;
      return acc;
    }, {});
    return {
      layers: layersDict,
      layersVisibility,
      activeLayers,
      drawLayer: null,
    };
  } catch (error) {
    return Promise.reject(getError({ error, prefix: LAYERS_FETCHING_ERROR_PREFIX }));
  }
});

export const addLayerForModel = createAsyncThunk<Layer | null, LayerStoreInterface, ThunkConfig>(
  'layers/addLayer',
  async ({ name, visible, locked, modelId, zIndex }) => {
    try {
      const { data } = await axiosInstanceNewAPI.post<Layer>(apiPath.storeLayer(modelId), {
        name,
        visible,
        locked,
        z: zIndex,
      });
      const isDataValid = validateDataUsingZodSchema(data, layerSchema);

      return isDataValid ? data : null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const updateLayer = createAsyncThunk<Layer | null, LayerUpdateInterface, ThunkConfig>(
  'layers/updateLayer',
  async ({ name, visible, locked, modelId, layerId, zIndex }) => {
    try {
      const { data } = await axiosInstanceNewAPI.put<Layer>(apiPath.updateLayer(modelId, layerId), {
        name,
        visible,
        locked,
        z: zIndex,
      });

      const isDataValid = validateDataUsingZodSchema(data, layerSchema);

      return isDataValid ? data : null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const removeLayer = createAsyncThunk<
  null,
  { modelId: number; layerId: number },
  ThunkConfig
>('layers/removeLayer', async ({ modelId, layerId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(apiPath.removeLayer(modelId, layerId));
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const getLayerImage = createAsyncThunk<
  LayerImage | null,
  {
    modelId: number;
    layerId: number;
    imageId: number;
  },
  ThunkConfig
>('layers/getLayerImage', async ({ modelId, layerId, imageId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<LayerImage>(
      apiPath.getLayerImageObject(modelId, layerId, imageId),
    );
    const isDataValid = validateDataUsingZodSchema(data, layerImageSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerImageObject = createAsyncThunk<
  LayerImage | null,
  {
    modelId: number;
    layerId: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    glyph: number | null;
  },
  ThunkConfig
>('layers/addLayerImageObject', async ({ modelId, layerId, x, y, z, width, height, glyph }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerImage>(
      apiPath.addLayerImageObject(modelId, layerId),
      {
        x,
        y,
        z,
        width,
        height,
        glyph,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerImageSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const updateLayerImageObject = createAsyncThunk<
  LayerImage | null,
  {
    modelId: number;
    layerId: number;
    id: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    glyph: number | null;
  },
  ThunkConfig
>(
  'layers/updateLayerImageObject',
  async ({ modelId, layerId, id, x, y, z, width, height, glyph }) => {
    try {
      const { data } = await axiosInstanceNewAPI.put<LayerImage>(
        apiPath.updateLayerImageObject(modelId, layerId, id),
        {
          x,
          y,
          z,
          width,
          height,
          glyph,
        },
      );
      const isDataValid = validateDataUsingZodSchema(data, layerImageSchema);
      if (isDataValid) {
        return data;
      }
      return null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const removeLayerImage = createAsyncThunk<
  null,
  { modelId: number; layerId: number; imageId: number },
  ThunkConfig
>('layers/removeLayerImage', async ({ modelId, layerId, imageId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(
      apiPath.removeLayerImageObject(modelId, layerId, imageId),
    );
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerText = createAsyncThunk<
  LayerText | null,
  {
    modelId: number;
    layerId: number;
    z: number;
    boundingBox: BoundingBox;
    textData: LayerTextFactoryForm;
  },
  ThunkConfig
>('layers/addLayerText', async ({ modelId, layerId, z, boundingBox, textData }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerText>(
      apiPath.addLayerText(modelId, layerId),
      {
        ...boundingBox,
        ...textData,
        z,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerTextSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const getLayerText = createAsyncThunk<
  LayerText | null,
  {
    modelId: number;
    layerId: number;
    textId: number;
  },
  ThunkConfig
>('layers/getLayerText', async ({ modelId, layerId, textId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<LayerText>(
      apiPath.getLayerText(modelId, layerId, textId),
    );
    const isDataValid = validateDataUsingZodSchema(data, layerTextSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const removeLayerText = createAsyncThunk<
  null,
  { modelId: number; layerId: number; textId: number },
  ThunkConfig
>('layers/removeLayerText', async ({ modelId, layerId, textId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(apiPath.removeLayerText(modelId, layerId, textId));
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const updateLayerText = createAsyncThunk<
  LayerText | null,
  {
    modelId: number;
    layerId: number;
    id: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    fontSize: number;
    notes: string;
    verticalAlign: VerticalAlign;
    horizontalAlign: HorizontalAlign;
    color: Color;
    borderColor: Color;
  },
  ThunkConfig
>(
  'layers/updateLayerText',
  async ({
    modelId,
    layerId,
    id,
    x,
    y,
    z,
    width,
    height,
    fontSize,
    notes,
    verticalAlign,
    horizontalAlign,
    color,
    borderColor,
  }) => {
    try {
      const { data } = await axiosInstanceNewAPI.put<LayerText>(
        apiPath.updateLayerText(modelId, layerId, id),
        {
          x,
          y,
          z,
          width,
          height,
          fontSize,
          notes,
          verticalAlign,
          horizontalAlign,
          color,
          borderColor,
        },
      );
      const isDataValid = validateDataUsingZodSchema(data, layerTextSchema);
      if (isDataValid) {
        return data;
      }
      return null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const getLayerRect = createAsyncThunk<
  LayerRect | null,
  {
    modelId: number;
    layerId: number;
    rectId: number;
  },
  ThunkConfig
>('layers/getLayerRect', async ({ modelId, layerId, rectId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<LayerRect>(
      apiPath.getLayerRect(modelId, layerId, rectId),
    );
    const isDataValid = validateDataUsingZodSchema(data, layerRectSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerRect = createAsyncThunk<
  LayerRect | null,
  {
    modelId: number;
    layerId: number;
    z: number;
    boundingBox: BoundingBox;
    rectData: LayerRectFactoryForm;
  },
  ThunkConfig
>('layers/addLayerRect', async ({ modelId, layerId, z, boundingBox, rectData }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerRect>(
      apiPath.addLayerRect(modelId, layerId),
      {
        ...boundingBox,
        ...rectData,
        z,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerRectSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const updateLayerRect = createAsyncThunk<
  LayerRect | null,
  {
    modelId: number;
    layerId: number;
    id: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    fillColor: Color;
    borderColor: Color;
  },
  ThunkConfig
>(
  'layers/updateLayerRect',
  async ({ modelId, layerId, id, x, y, z, width, height, fillColor, borderColor }) => {
    try {
      const { data } = await axiosInstanceNewAPI.put<LayerRect>(
        apiPath.updateLayerRect(modelId, layerId, id),
        {
          x,
          y,
          z,
          width,
          height,
          fillColor,
          borderColor,
        },
      );
      const isDataValid = validateDataUsingZodSchema(data, layerRectSchema);
      if (isDataValid) {
        return data;
      }
      return null;
    } catch (error) {
      return Promise.reject(getError({ error }));
    }
  },
);

export const removeLayerRect = createAsyncThunk<
  null,
  { modelId: number; layerId: number; rectId: number },
  ThunkConfig
>('layers/removeLayerRect', async ({ modelId, layerId, rectId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(apiPath.removeLayerRect(modelId, layerId, rectId));
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const getLayerOval = createAsyncThunk<
  LayerOval | null,
  {
    modelId: number;
    layerId: number;
    ovalId: number;
  },
  ThunkConfig
>('layers/getLayerOval', async ({ modelId, layerId, ovalId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<LayerOval>(
      apiPath.getLayerOval(modelId, layerId, ovalId),
    );
    const isDataValid = validateDataUsingZodSchema(data, layerOvalSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerOval = createAsyncThunk<
  LayerOval | null,
  {
    modelId: number;
    layerId: number;
    z: number;
    boundingBox: BoundingBox;
    ovalData: LayerOvalFactoryForm;
  },
  ThunkConfig
>('layers/addLayerOval', async ({ modelId, layerId, z, boundingBox, ovalData }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerOval>(
      apiPath.addLayerOval(modelId, layerId),
      {
        ...boundingBox,
        ...ovalData,
        z,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerOvalSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const updateLayerOval = createAsyncThunk<
  LayerOval | null,
  {
    modelId: number;
    layerId: number;
    id: number;
    x: number;
    y: number;
    z: number;
    width: number;
    height: number;
    color: Color;
  },
  ThunkConfig
>('layers/updateLayerOval', async ({ modelId, layerId, id, x, y, z, width, height, color }) => {
  try {
    const { data } = await axiosInstanceNewAPI.put<LayerOval>(
      apiPath.updateLayerOval(modelId, layerId, id),
      {
        x,
        y,
        z,
        width,
        height,
        color,
      },
    );
    const isDataValid = validateDataUsingZodSchema(data, layerOvalSchema);
    if (isDataValid) {
      return data;
    }
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const removeLayerOval = createAsyncThunk<
  null,
  { modelId: number; layerId: number; ovalId: number },
  ThunkConfig
>('layers/removeLayerOval', async ({ modelId, layerId, ovalId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(apiPath.removeLayerOval(modelId, layerId, ovalId));
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const getLayerLine = createAsyncThunk<
  LayerLine | null,
  {
    modelId: number;
    layerId: number;
    lineId: number;
  },
  ThunkConfig
>('layers/getLayerLine', async ({ modelId, layerId, lineId }) => {
  try {
    const { data } = await axiosInstanceNewAPI.get<LayerLine>(
      apiPath.getLayerLine(modelId, layerId, lineId),
    );
    const isDataValid = validateDataUsingZodSchema(data, layerLineSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const addLayerLine = createAsyncThunk<
  LayerLine | null,
  {
    modelId: number;
    layerId: number;
    payload: LayerLineFactoryPayload;
  },
  ThunkConfig
>('layers/addLayerLine', async ({ modelId, layerId, payload }) => {
  try {
    const { data } = await axiosInstanceNewAPI.post<LayerLine>(
      apiPath.addLayerLine(modelId, layerId),
      payload,
    );
    const isDataValid = validateDataUsingZodSchema(data, layerLineSchema);

    return isDataValid ? data : null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const updateLayerLine = createAsyncThunk<
  LayerLine | null,
  {
    modelId: number;
    layerId: number;
    lineId: number;
    payload: LayerLineEditFactoryPayload;
  },
  ThunkConfig
>('layers/updateLayerLine', async ({ modelId, layerId, lineId, payload }) => {
  try {
    const { data } = await axiosInstanceNewAPI.put<LayerLine>(
      apiPath.updateLayerLine(modelId, layerId, lineId),
      payload,
    );
    const isDataValid = validateDataUsingZodSchema(data, layerLineSchema);
    if (isDataValid) {
      return data;
    }
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});

export const removeLayerLine = createAsyncThunk<
  null,
  { modelId: number; layerId: number; lineId: number },
  ThunkConfig
>('layers/removeLayerLine', async ({ modelId, layerId, lineId }) => {
  try {
    await axiosInstanceNewAPI.delete<void>(apiPath.removeLayerLine(modelId, layerId, lineId));
    return null;
  } catch (error) {
    return Promise.reject(getError({ error }));
  }
});
