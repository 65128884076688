/* eslint-disable no-magic-numbers */
import { PayloadAction } from '@reduxjs/toolkit';
import { MAP_EDIT_ACTIONS } from '@/redux/mapEditTools/mapEditTools.constants';
import { MapEditToolsState } from '@/redux/mapEditTools/mapEditTools.types';
import { LayerImage, LayerLine, LayerOval, LayerRect, LayerText } from '@/types/models';

export const mapEditToolsSetActiveActionReducer = (
  state: MapEditToolsState,
  action: PayloadAction<keyof typeof MAP_EDIT_ACTIONS | null>,
): void => {
  state.activeAction = action.payload;
};

export const mapEditToolsSetLayerObjectReducer = (
  state: MapEditToolsState,
  action: PayloadAction<LayerImage | LayerText | LayerRect | LayerOval | null>,
): void => {
  state.layerObject = action.payload;
};

export const mapEditToolsSetLayerLineReducer = (
  state: MapEditToolsState,
  action: PayloadAction<LayerLine | null>,
): void => {
  state.layerLine = action.payload;
};
