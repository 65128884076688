import { rootSelector } from '@/redux/root/root.selectors';
import { createSelector } from '@reduxjs/toolkit';
import { projectIdSelector } from '@/redux/project/project.selectors';
import { hasPrivilegeToObject } from '@/redux/user/user.utils';
import { UserPrivilege } from '@/types/models';
import { USER_ROLE } from '@/constants/user';

export const userSelector = createSelector(rootSelector, state => state.user);

export const authenticatedUserSelector = createSelector(userSelector, state => state.authenticated);
export const loadingUserSelector = createSelector(userSelector, state => state.loading);
export const loginUserSelector = createSelector(userSelector, state => state.login);
export const userRoleSelector = createSelector(userSelector, state => state.role);
export const userPrivilegesSelector = createSelector(
  userSelector,
  state => state.userData?.privileges || [],
);
export const hasPrivilegeToWriteProjectSelector = createSelector(
  userPrivilegesSelector,
  projectIdSelector,
  userRoleSelector,
  (
    userPrivileges: UserPrivilege[],
    projectId: string | undefined,
    role: string | null,
  ): boolean => {
    if (!projectId || !role) {
      return false;
    }
    return (
      role === USER_ROLE.ADMIN || hasPrivilegeToObject(userPrivileges, 'WRITE_PROJECT', projectId)
    );
  },
);
export const userTokenSelector = createSelector(userSelector, state => state.token);
