import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';

export const layerRectSchema = z.object({
  id: z.number().int().positive(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  layer: z.number(),
  lineWidth: z.number(),
  fillColor: colorSchema,
  borderColor: colorSchema,
});
