import { createSelector } from '@reduxjs/toolkit';
import { OVERVIEW_IMAGE_ID_DEFAULT, MOL_ART_UNIPROT_ID_DEFAULT } from '../map/map.constants';
import { rootSelector } from '../root/root.selectors';

export const modalSelector = createSelector(rootSelector, state => state.modal);

export const isModalOpenSelector = createSelector(modalSelector, state => state.isOpen);

export const currentOverviewImageIdSelector = createSelector(
  modalSelector,
  modal => modal?.overviewImagesState.imageId || OVERVIEW_IMAGE_ID_DEFAULT,
);

export const currentSelectedBioEntityIdSelector = createSelector(
  modalSelector,
  modal => modal?.molArtState.uniprotId || MOL_ART_UNIPROT_ID_DEFAULT,
);

export const currentEditedOverlaySelector = createSelector(
  modalSelector,
  modal => modal.editOverlayState,
);

export const currentEditedOverlayGroupSelector = createSelector(
  modalSelector,
  modal => modal.editOverlayGroupState,
);

export const layerFactoryStateSelector = createSelector(
  modalSelector,
  modal => modal.layerFactoryState,
);

export const currentErrorDataSelector = createSelector(
  modalSelector,
  modal => modal?.errorReportState.errorData || undefined,
);

export const layerObjectFactoryStateSelector = createSelector(
  modalSelector,
  modal => modal.layerObjectFactoryState,
);

export const layerLineFactoryStateSelector = createSelector(
  modalSelector,
  modal => modal.layerLineFactoryState,
);

export const elementGlyphStateSelector = createSelector(
  modalSelector,
  modal => modal.elementGlyphState,
);
