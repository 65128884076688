import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';
import { referenceSchema } from '@/models/referenceSchema';
import { submodelSchema } from '@/models/submodelSchema';
import { modelElementModificationSchema } from '@/models/modelElementModificationSchema';
import { glyphSchema } from '@/models/glyphSchema';

export const modelElementSchema = z.object({
  id: z.number().int().positive(),
  model: z.number(),
  glyph: glyphSchema.nullable(),
  submodel: submodelSchema.nullable(),
  compartment: z.number().nullable(),
  pathway: z.number().nullable(),
  immediateLink: z.string().nullable().optional(),
  elementId: z.string(),
  x: z.number(),
  y: z.number(),
  z: z.number(),
  width: z.number(),
  height: z.number(),
  fontSize: z.number(),
  fontColor: colorSchema,
  fillColor: colorSchema,
  borderColor: colorSchema,
  visibilityLevel: z.string(),
  transparencyLevel: z.string(),
  notes: z.string(),
  symbol: z.string().nullable(),
  fullName: z.string().nullable(),
  abbreviation: z.string().nullable(),
  formula: z.string().nullable(),
  name: z.string(),
  nameX: z.number(),
  nameY: z.number(),
  nameWidth: z.number(),
  nameHeight: z.number(),
  nameVerticalAlign: z.enum(['TOP', 'MIDDLE', 'BOTTOM']),
  nameHorizontalAlign: z.enum(['LEFT', 'RIGHT', 'CENTER']),
  synonyms: z.array(z.string()),
  formerSymbols: z.array(z.string()),
  activity: z.boolean().optional(),
  lineWidth: z.number().optional(),
  innerWidth: z.number().optional(),
  outerWidth: z.number().optional(),
  thickness: z.number().optional(),
  shape: z.enum(['SQUARE_COMPARTMENT', 'OVAL_COMPARTMENT', 'PATHWAY']).optional(),
  complex: z.number().nullable().optional(),
  initialAmount: z.number().nullable().optional(),
  charge: z.number().nullable().optional(),
  initialConcentration: z.number().nullable().optional(),
  onlySubstanceUnits: z.boolean().nullable().optional(),
  homodimer: z.number().optional(),
  hypothetical: z.boolean().nullable().optional(),
  boundaryCondition: z.boolean().nullable().optional(),
  constant: z.boolean().nullable().optional(),
  substanceUnits: z.boolean().nullable().optional(),
  borderLineType: z.string().optional(),
  references: z.array(referenceSchema),
  sboTerm: z.string(),
  modificationResidues: z.array(modelElementModificationSchema).optional(),
});
