import { createSlice } from '@reduxjs/toolkit';
import { MAP_INITIAL_STATE } from './map.constants';
import {
  closeMapAndSetMainMapActiveReducer,
  closeMapReducer,
  initMapBackgroundsReducer,
  initMapPositionReducers,
  initMapSizeAndModelIdReducer,
  initOpenedMapsReducer,
  openMapAndOrSetActiveIfSelectedReducer,
  openMapAndSetActiveReducer,
  setActiveMapReducer,
  setLastPositionZoomReducer,
  setMapBackgroundReducer,
  setMapDataReducer,
  setMapPositionReducer,
  updateLastClickReducer,
  updateLastRightClickReducer,
  varyPositionZoomReducer,
} from './map.reducers';

const mapSlice = createSlice({
  name: 'map',
  initialState: MAP_INITIAL_STATE,
  reducers: {
    setMapData: setMapDataReducer,
    setActiveMap: setActiveMapReducer,
    openMapAndSetActive: openMapAndSetActiveReducer,
    closeMap: closeMapReducer,
    closeMapAndSetMainMapActive: closeMapAndSetMainMapActiveReducer,
    setMapPosition: setMapPositionReducer,
    varyPositionZoom: varyPositionZoomReducer,
    setMapBackground: setMapBackgroundReducer,
    openMapAndOrSetActiveIfSelected: openMapAndOrSetActiveIfSelectedReducer,
    setLastPositionZoom: setLastPositionZoomReducer,
    updateLastClick: updateLastClickReducer,
    updateLastRightClick: updateLastRightClickReducer,
  },
  extraReducers: builder => {
    initMapPositionReducers(builder);
    initMapSizeAndModelIdReducer(builder);
    initMapBackgroundsReducer(builder);
    initOpenedMapsReducer(builder);
  },
});

export const {
  setMapData,
  setActiveMap,
  openMapAndSetActive,
  closeMap,
  closeMapAndSetMainMapActive,
  setMapPosition,
  setMapBackground,
  varyPositionZoom,
  openMapAndOrSetActiveIfSelected,
  setLastPositionZoom,
  updateLastClick,
  updateLastRightClick,
} = mapSlice.actions;

export default mapSlice.reducer;
