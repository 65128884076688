import { LayerRect } from '@/types/models';
import { LayersDictState } from '@/redux/layers/layers.types';

export default function setLayerRect({
  layerId,
  layers,
  layerRect,
}: {
  layerId: number;
  layers: LayersDictState;
  layerRect: LayerRect;
}): void {
  const layer = layers[layerId];
  if (!layer) {
    return;
  }
  layer.rects[layerRect.id] = layerRect;
}
