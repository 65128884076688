import { z } from 'zod';
import { colorSchema } from '@/models/colorSchema';
import { segmentSchema } from '@/models/segmentSchema';
import { arrowSchema } from '@/models/arrowSchema';

export const layerLineSchema = z.object({
  id: z.number().int().positive(),
  width: z.number(),
  color: colorSchema,
  z: z.number(),
  segments: z.array(segmentSchema),
  startArrow: arrowSchema,
  endArrow: arrowSchema,
  lineType: z.string(),
  layer: z.number(),
});
