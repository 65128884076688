/* eslint-disable no-magic-numbers */
import { ONE } from '@/constants/common';
import { overlayBioEntitySchema } from '@/models/overlayBioEntitySchema';
import { OverlayBioEntityRender } from '@/types/OLrendering';
import { OverlayBioEntity } from '@/types/models';
import { getOverlayReactionCoordsFromLine } from '@/utils/overlays/getOverlayReactionCoords';
import { isBioEntity, isReaction, isSubmapLink } from '@/utils/overlays/overlaysElementsTypeGuards';
import { z } from 'zod';
import { OverlaysIdsAndOrder } from './overlayBioEntity.types';

export const parseOverlayBioEntityToOlRenderingFormat = (
  data: OverlayBioEntity[],
  overlayId: number,
): OverlayBioEntityRender[] =>
  data.reduce((acc: OverlayBioEntityRender[], entity: OverlayBioEntity) => {
    /**
     * The're two types of entities - bioentity and reaction
     * Bioentity comes with the single only element
     * And reaction comes with many different lines that needs to be merged together
     * Every reaction line is a different entity after reduce
     */

    if (isSubmapLink(entity)) {
      acc.push({
        type: 'submap-link',
        id: entity.left.id,
        modelId: entity.left.model,
        x1: entity.left.x,
        y1: entity.left.y + entity.left.height,
        x2: entity.left.x + entity.left.width,
        y2: entity.left.y,
        width: entity.left.width,
        height: entity.left.height,
        value: entity.right.value,
        overlayId,
        color: entity.right.color,
        name: entity.right.name,
      });
      return acc;
    }

    if (isBioEntity(entity)) {
      acc.push({
        type: 'rectangle',
        id: entity.left.id,
        modelId: entity.left.model,
        x1: entity.left.x,
        y1: entity.left.y + entity.left.height,
        x2: entity.left.x + entity.left.width,
        y2: entity.left.y,
        width: entity.left.width,
        height: entity.left.height,
        value: entity.right.value,
        overlayId,
        color: entity.right.color,
        geneVariants: entity.right.geneVariants,
        name: entity.right.name,
      });
    }

    if (isReaction(entity)) {
      const { products, reactants, modifiers } = entity.left;
      const lines = [products, reactants, modifiers].flat().map(element => element.line);
      const coords = lines.map(getOverlayReactionCoordsFromLine).flat();
      const elements = coords.map(
        ({ x1, x2, y1, y2, id, width, height }): OverlayBioEntityRender => ({
          type: 'line',
          id,
          modelId: entity.left.model,
          x1,
          x2,
          y1,
          y2,
          width,
          height: Math.abs(height),
          value: entity.right.value,
          overlayId,
          color: entity.right.color,
        }),
      );

      acc.push(...elements);
    }

    return acc;
  }, []);

export type OverlayIdAndOrder = {
  id: number;
  order: number;
};

export type OverlayOrder = {
  id: number;
  order: number;
  calculatedOrder: number;
  index: number;
};

const byOrderOrId = (a: OverlayOrder, b: OverlayOrder): number => {
  if (a.order === b.order) {
    return a.id - b.id;
  }
  return a.order - b.order;
};

/** function calculates order of the function based on "order" property in ovarlay data. */
export const calculateOvarlaysOrder = (
  overlaysIdsAndOrder: OverlayIdAndOrder[],
): OverlayOrder[] => {
  const overlaysOrder = overlaysIdsAndOrder.map(({ id, order }, index) => ({
    id,
    order,
    calculatedOrder: 0,
    index,
  }));

  overlaysOrder.sort(byOrderOrId);

  overlaysOrder.forEach((overlay, index) => {
    const updatedOverlay = { ...overlay };
    updatedOverlay.calculatedOrder = index + ONE;
    updatedOverlay.index = index;
    overlaysOrder[index] = updatedOverlay;
  });

  return overlaysOrder;
};

const isValidOverlayBioEntity = (overlayBioEntity: OverlayBioEntity): boolean => {
  return overlayBioEntitySchema.safeParse(overlayBioEntity).success;
};

type OverlayBioEntities = OverlayBioEntity[];

export const getValidOverlayBioEntities = (
  unvalidatedOverlayBioEntities: OverlayBioEntities,
): OverlayBioEntities | undefined => {
  const filteredValidOverlayBioEntitiesSchema = z
    .array(z.any())
    .transform(overlayBioEntities => overlayBioEntities.filter(isValidOverlayBioEntity));

  const parsedOverlayBioEntities = filteredValidOverlayBioEntitiesSchema.safeParse(
    unvalidatedOverlayBioEntities,
  );

  return parsedOverlayBioEntities.success ? parsedOverlayBioEntities.data : undefined;
};

type GetActiveOverlaysIdsAndOrderReturnType = {
  maxOrderValue: number;
  activeOverlaysIdsAndOrder: OverlaysIdsAndOrder;
};

export const getActiveOverlaysIdsAndOrder = (
  overlaysIdsAndOrder: OverlaysIdsAndOrder,
  activeOverlaysIds: number[],
): GetActiveOverlaysIdsAndOrderReturnType => {
  let maxOrderValue = -Infinity;
  const activeOverlaysIdsAndOrder = overlaysIdsAndOrder.filter(({ id }) =>
    activeOverlaysIds.includes(id),
  );

  overlaysIdsAndOrder.forEach(({ id, order }) => {
    const isActive = activeOverlaysIds.includes(id);
    if (isActive && order > maxOrderValue) maxOrderValue = order;
  });

  if (maxOrderValue === -Infinity) {
    maxOrderValue = 0;
  }

  return {
    maxOrderValue,
    activeOverlaysIdsAndOrder,
  };
};

export const getActiveUserOverlaysIdsAndOrder = (
  userOverlaysIdsAndOrder: OverlaysIdsAndOrder,
  activeOverlaysIds: number[],
  maxOrderValue: number,
): OverlaysIdsAndOrder => {
  const clonedUserOverlaysIdsAndOrder: OverlaysIdsAndOrder = JSON.parse(
    JSON.stringify(userOverlaysIdsAndOrder),
  );

  const activeUserOverlaysIdsAndOrder = clonedUserOverlaysIdsAndOrder.filter(userOverlay => {
    const isActive = activeOverlaysIds.includes(userOverlay.id);
    if (isActive) {
      /* eslint-disable-next-line no-param-reassign */
      userOverlay.order = maxOrderValue + userOverlay.order; // user overlays appear after shared overlays, we need to get max order value of shared overlays and add to it user overlay order to be ensured that user overlay will appear after shared overlays
    }
    return isActive;
  });

  return activeUserOverlaysIdsAndOrder;
};
