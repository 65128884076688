interface ImageIconProps {
  className?: string;
}

export const ImageIcon = ({ className }: ImageIconProps): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 200 200"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="20"
      width="190"
      height="155"
      stroke="black"
      strokeWidth="6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="40" cy="55" r="20" fill="black" />
    <path d="M5 175 L5 155 L50 105 L80 135 L140 65 L195 135 L195 175 Z" fill="black" />
  </svg>
);
