export const ENTITY_OPERATION_TYPES = {
  ENTITY_CREATED: 'ENTITY_CREATED',
  ENTITY_UPDATED: 'ENTITY_UPDATED',
  ENTITY_DELETED: 'ENTITY_DELETED',
} as const;

export const ACKNOWLEDGE_OPERATION_TYPES = {
  MESSAGE_PROCESSED_SUCCESSFULLY: 'MESSAGE_PROCESSED_SUCCESSFULLY',
} as const;

export const ENTITY_TYPES = {
  GLYPH: 'GLYPH',
  LAYER: 'LAYER',
  LAYER_IMAGE: 'LAYER_IMAGE',
  LAYER_LINE: 'LAYER_LINE',
  LAYER_OVAL: 'LAYER_OVAL',
  LAYER_RECTANGLE: 'LAYER_RECTANGLE',
  LAYER_TEXT: 'LAYER_TEXT',
} as const;
